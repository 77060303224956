import { Municipality } from "../dto/Municipality";
import { Candidate } from "../dto/Candidate";
import { MunicipalityQuery } from "./MunicipalityQuery";

export class AMMRestService
{
    private static _current: AMMRestService = null;

    public static current(): AMMRestService
    {
        if(!this._current)
        {
            this._current = new AMMRestService();
        }

        return this._current;
    }

    private municipalities: MunicipalityQuery;

    constructor()
    {
        const apiUrl = process.env['REACT_APP_API_URL'] + '/public';
        this.municipalities = new MunicipalityQuery(apiUrl);
    }

    public async getMunicipalities(): Promise<Municipality[]>
    {
        const munis = await this.municipalities.get();
        return munis.map(m => Municipality.fromJson(m));

        // let url = this.generateUrl('https://firestore.googleapis.com/v1beta1/projects/amm-votes/databases/(default)/documents/municipalities',
        //     ['name', 'type', 'district', 'targetResults', 'resultsDeclared' ]);

        // url += '&pageSize=200';

        // let options: RequestInit = {
        //     method: 'GET',
        //     cache: 'no-cache',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     }
        // };

        // return fetch(url, options).then((response) => 
        // {
        //     if(response.ok)
        //     {
        //         return response.json().then((json) => 
        //         {
        //             return json.documents.map((d) => 
        //             {
        //                 return Municipality.fromJsonOld(d);
        //             });
        //         });
        //     }
        //     else
        //     {
        //         return null;
        //     }
        // });
    }

    public getCandidatesForMunicipality(municipalityId: string): Promise<Candidate[]>
    {
        return this.municipalities.getById(municipalityId).candidates.get();

        // let url = "https://firestore.googleapis.com/v1beta1/projects/amm-votes/databases/(default)/documents:runQuery";

        // let query = {
        //     structuredQuery: {
        //         'where': {
        //             'fieldFilter': { 
        //                 'field': {
        //                     'fieldPath': 'municipality'
        //                 }, 
        //                 'op': 5, 
        //                 'value': {
        //                     'stringValue': municipalityId
        //                 }
        //             }
        //         },
        //         "from": [
        //             {
        //                 "collectionId": "candidates"
        //             }
        //         ]
        //     }
        // };

        // let options: RequestInit = {
        //     method: 'POST',
        //     cache: 'no-cache',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(query)
        // };

        // return fetch(url, options).then((response) => 
        // {
        //     if(response.ok)
        //     {
        //         return response.json().then((json) => 
        //         {
        //             if(json.length === 1 && !json[0].document)
        //             {
        //                 return [];
        //             }
        //             else
        //             {
        //                 return json.map((item) => 
        //                 {
        //                     return Candidate.fromJson(item.document);
        //                 });
        //             }
        //         });
        //     }
        //     else
        //     {
        //         return null;
        //     }
        // });
    }

    private generateUrl(baseUrl: string, selectFields?: string[])
    {
        let query = '';
        if(selectFields && selectFields.length > 0)
        {
            query = '?mask.fieldPaths=' + selectFields.join('&mask.fieldPaths=');
        }

        return baseUrl + query;
    }
}