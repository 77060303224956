import { Component } from "react";
import AnimateHeight from 'react-animate-height';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Candidate, ElectedStatus } from '../dto/Candidate';
import i18n from "../utils/i18n";
import Utils from "../utils/Utils";

export interface PositionListItemProps
{
    className?: string;
    name?: string;
    candidates?: Candidate[];
    resultsDeclared?: boolean;
}

export interface PositionListItemState
{
    open: boolean;
}

export class PositionListItem extends Component<PositionListItemProps, PositionListItemState>
{
    public static defaultProps: Partial<PositionListItemProps> = 
    {
        resultsDeclared: false
    };

    constructor(props: PositionListItemProps)
    {
        super(props);

        this.state = {
            open: false
        };
    }

    public componentDidMount()
    {
    }

    public componentWillUnmount()
    {
        
    }

    public render()
    {
        const position = this.props.name || 'Unnamed Position';
        let showCandidatesButton = true;
        let height: React.ReactText = 'auto';

        const visibleItems = [];
        let collapsedItems = [];

        if(this.props.candidates)
        {
            const totalVotes = this.props.candidates.reduce((accumulator, currentValue) => { return accumulator + currentValue.votes; }, 0);
            let sortedCandidates: Candidate[] = []; 
            
            if(this.props.resultsDeclared)
                sortedCandidates = this.props.candidates.slice(0).sort(Utils.sortByProperties([{ property: 'elected', direction: 'desc' }, { property: 'votes', direction: 'desc' }]));
            else
                sortedCandidates = this.props.candidates.slice(0).sort(Utils.sortByProperties([{ property: 'order', direction: 'asc' }, { property: 'lastName', direction: 'asc' }]));

            if(this.props.resultsDeclared)
            {
                sortedCandidates.forEach((c) => 
                {
                    let percentValue = c.votes / totalVotes * 100
                    let percentString = (Math.round((c.votes / totalVotes) * 10000) / 100);
                    const electedStatus = Candidate.getElectedStatusString(c.elected);

                    if(!isFinite(percentValue)) { percentValue = 100; }
                    if(!isFinite(percentString)) { percentString = 100; }

                    const votesContent = c.elected !== ElectedStatus.Acclaimed ? `${c.votes} ${i18n.t('CandidatesListItem-Votes')}` : '';
                    const percentContent = c.elected !== ElectedStatus.Acclaimed ? `${percentString}%` : '';

                    const item = (
                        <li key={c.id} className={'candidate-declared' + (c.elected ? ' elected' : '')}>
                            <div className="candidate-row">
                                <div>{Candidate.fullName(c)}</div>
                                <div>{votesContent}</div>
                                <div className={c.elected ? 'elected': ''}>{percentContent}</div>
                                <div className={c.elected ? 'elected': ''}>{electedStatus}</div>
                            </div>
                            <div className="candidate-row-bar">
                                <div><LinearProgress 
                                    variant="determinate" 
                                    className="radius-masked"
                                    color={c.elected ? 'secondary': 'primary'} 
                                    style={{ height: '10px', borderRadius: '5px', margin: '5px 0' }}
                                    value={percentValue} /></div>
                            </div>
                        </li>
                    );

                    if(c.elected)
                    {
                        visibleItems.push(item);
                    }
                    else
                    {
                        collapsedItems.push(item);
                    }
                });

                if(collapsedItems.length === 0)
                    showCandidatesButton = false;
            }
            else
            {
                collapsedItems = sortedCandidates.map((c) => 
                {
                    return (
                        <li key={c.id} className="candidate-undeclared">
                            <p>{Candidate.fullName(c)}</p>
                            {/* <p>{c.previouslyElected ? 'Running for Re-election' : 'New Candidate'}</p> */}
                        </li>
                    );
                });
            }

            height = this.state.open ? 'auto': 0;
        }

        return (
            <div className={"component-position-listitem" + (this.state.open ? ' open' : '')} onClick={this.onClick.bind(this)}>
                <div className="position-name">
                    <h3>{position}</h3>
                    {
                        showCandidatesButton &&
                        <div>
                            { this.state.open ? 
                                i18n.t('CandidatesListItem-Hide') : 
                                i18n.t('CandidatesListItem-Show') } <i className="fa fa-chevron-down" />
                        </div>
                    }
                </div>

                <ul className="position-candidate-list">
                    {visibleItems}
                    <AnimateHeight duration={300} height={height} className={'collapse-items' + (this.state.open ? ' open' : '')}>
                        {collapsedItems}
                    </AnimateHeight>
                </ul>
            </div>
        )
    }

    private onClick()
    {
        this.setState({ open: !this.state.open });
    }
}