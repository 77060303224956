import { Component } from "react";

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { AMMDataService } from '../api/AMMDataService';
import { Municipality } from "../dto/Municipality";
import { Account } from "../dto/Account";
import { MunicipalityDialog } from "../dialogs/MunicipalityDialog";
import { MunicipalityDeleteDialog } from "../dialogs/MunicipalityDeleteDialog";
import { RouteComponentProps, Link } from "react-router-dom";
import { Spinner } from "../components/Spinner";
import { UserContext } from "./UserContext";
import { RoleTrim } from "../components/RoleTrim";
import { Breadcrumb } from "../components/Breadcrumb";
import { BreadcrumbLink } from "../components/BreadcrumbLink";
import { Candidate } from "../dto/Candidate";
import Utils from "../utils/Utils";

export interface MunicipalitiesTabProps extends RouteComponentProps<any>
{
    currentUser: Account;
}

export interface MunicipalitiesTabState 
{
    municipalities: Municipality[];
    selectedMunicipality: Municipality;
    createDialogOpen: boolean;
    deleteDialogOpen: boolean;
    isLoading: boolean;
}

export class MunicipalitiesTab extends Component<MunicipalitiesTabProps, MunicipalitiesTabState>
{
    constructor(props: MunicipalitiesTabProps) 
    {
        super(props);

        this.state = {
            municipalities: [],
            selectedMunicipality: null,
            createDialogOpen: false,
            deleteDialogOpen: false,
            isLoading: true
        };
    }

    public componentDidMount() 
    {
        this.refreshMunicipalities();
    }

    public componentWillUnmount() 
    {

    }

    public refreshMunicipalities()
    {
        if(this.props.currentUser)
        {
            if(this.props.currentUser.role === 'admin')
            {
                AMMDataService.current().getMunicipalities().then((docs) => 
                {
                    this.setState({ municipalities: docs.sort(Utils.sortByProperty('name')), isLoading: false });
                });
            }
            else if(this.props.currentUser.role === 'seo')
            {
                AMMDataService.current().getMunicipalitiesForSEO(this.props.currentUser.uid).then((docs) => 
                {
                    this.setState({ municipalities: docs.sort(Utils.sortByProperty('name')), isLoading: false });
                });
            }
        }
    }

    public render() 
    {
        const path = this.props.match.path;
        const data = this.state.municipalities;
        let tableContent = null;

        if(this.state.isLoading === false)
        {
            tableContent = data.map((d) => {
                return (
                    <TableRow key={d.id}>
                        <TableCell component="th" scope="row">
                            <Link to={`${path}/${d.id}`}>{d.name}</Link>
                            
                            {
                                d.resultsDeclared &&
                                <span className="dot declared" title="Results have been declared for this municipality"></span>
                            }

                        </TableCell>
                        <TableCell>{d.type}</TableCell>
                        <TableCell>{d.district}</TableCell>
                        <RoleTrim allowedRoles="admin">
                            <TableCell>
                                    <Button size="small" variant="outlined" onClick={(e) => { this.setState({ createDialogOpen: true, selectedMunicipality: d }) }}>Edit</Button>
                                    <Button size="small" variant="outlined" style={{ marginLeft: '10px'}} onClick={e => this.setState({ deleteDialogOpen: true, selectedMunicipality: d })}>
                                        Delete
                                    </Button>
                            </TableCell>
                        </RoleTrim>
                    </TableRow>
                );
            });
        }
        else
        {
            tableContent = (
                <TableRow>
                    <TableCell colSpan={4}><Spinner /></TableCell>
                </TableRow>
            );
        }

        return (
            <div className="tab-municipalities">
                <Breadcrumb>
                    <BreadcrumbLink>Municipality List</BreadcrumbLink>
                </Breadcrumb>

                <h1>Municipalities</h1>

                <Paper>
                    <h3>Municipalities</h3>
                    <p>Below is a list of all municipalities. Click on a location below to view, edit or delete a municipality.</p>
                    <RoleTrim allowedRoles="admin">
                        <Button 
                            onClick={(e) => { this.setState({ createDialogOpen: true, selectedMunicipality: null }); }}
                            variant="contained" 
                            color="primary">
                            Add Municipality
                        </Button>

                        <Button
                            onClick={(e) => { this.exportCandidates(); }}
                            variant="outlined"
                            style={{ marginLeft: '10px' }}
                            color="primary">
                            Export Full Candidate Data to CSV
                        </Button>

                        <Button
                            onClick={(e) => { this.exportCandidatesForMedia(); }}
                            variant="outlined"
                            style={{ marginLeft: '10px' }}
                            color="primary">
                            Export Limited Candidate Data to CSV (for Media)
                        </Button>

                    </RoleTrim>

                    <Table style={{ marginTop: '10px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>District</TableCell>
                                <RoleTrim allowedRoles="admin">
                                    <TableCell>Actions</TableCell>
                                </RoleTrim>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableContent}
                        </TableBody>
                    </Table>

                </Paper>

                <MunicipalityDeleteDialog
                    municipality={this.state.selectedMunicipality}
                    isOpen={this.state.deleteDialogOpen}
                    onDeleted={this.handleMunicipalityDeleted.bind(this)}
                    onClose={this.handleDeleteClose.bind(this)}
                />

                <MunicipalityDialog
                    municipality={this.state.selectedMunicipality} 
                    isOpen={this.state.createDialogOpen}
                    onCreated={this.handleMunicipalityCreated.bind(this)}
                    onSaved={this.handleMunicipalitySaved.bind(this)}
                    onClose={this.handleCreateClose.bind(this)} />

            </div>
        );
    }

    private handleMunicipalityCreated(municipalityId: string)
    {
        this.refreshMunicipalities();
        this.setState({ createDialogOpen: false });

        this.props.history.push(`/admin/municipalities/${municipalityId}`);
    }

    private handleMunicipalitySaved()
    {
        this.refreshMunicipalities();
        this.setState({ createDialogOpen: false });
    }

    private handleCreateClose()
    {
        this.setState({ createDialogOpen: false });
    }

    private handleMunicipalityDeleted()
    {
        this.refreshMunicipalities();
        this.setState({ deleteDialogOpen: false });
    }

    private handleDeleteClose()
    {
        this.setState({ deleteDialogOpen: false });
    }

    private exportCandidatesForMedia()
    {
        const results: Array<{ 'candidate': Candidate, 'muni': Municipality }> = [];
        let content = 'firstName,lastName,position,elected,votes,ward,muni_name,muni_type,muni_resultsDeclared\n';

        AMMDataService.current().getCandidates().then((candidates) => 
        {
            AMMDataService.current().getMunicipalities().then((municipalities) => 
            {
                const munis = {};
                municipalities.forEach((m) => 
                {
                    munis[m.id] = m;
                });

                candidates.forEach(c => 
                {
                    let m: Municipality = munis[c.municipality];

                    if(m)
                    {
                        results.push({
                            'candidate': c,
                            'muni': m
                        });
                    }
                    else
                    {
                        console.error('No municipality found for id ' + c.municipality);
                    }
                });

                results.sort((a, b) => 
                {
                    if(a.muni.name < b.muni.name) { return -1; }
                    else if(a.muni.name > b.muni.name) { return 1; }
                    else {
                        if(a.muni.type < b.muni.type) { return -1; }
                        else if(a.muni.type > b.muni.type) { return 1; }
                        else { return 0; }
                    }
                });

                results.forEach((r) => 
                {
                    let m = r.muni;
                    let c = r.candidate;

                    let electedString = m.resultsDeclared ? Candidate.getElectedStatusString(c.elected) : '';
                    let votes = m.resultsDeclared ? c.votes || '' : '';
                    let order = c.order || '';
                    let ward = '';

                    if(c.position === "Councillor" || c.position === "LUD")
                        ward = c.ward;

                    content += `"${c.firstName}","${c.lastName}","${c.position}","${electedString}","${votes}","${ward}",`;
                    content += `"${m.name}","${m.type}","${m.resultsDeclared}"\n`;
                });
        
                this.downloadFile('candidates-media.csv', content);
            });
        });
    }

    private exportCandidates()
    {
        const results: Array<{ 'candidate': Candidate, 'muni': Municipality }> = [];
        let content = 'firstName,lastName,gender,position,elected,votes,previouslyElected,previouslyServed,order,ward,muni_name,muni_district,muni_type,muni_resultsDeclared\n';

        AMMDataService.current().getCandidates().then((candidates) => 
        {
            AMMDataService.current().getMunicipalities().then((municipalities) => 
            {
                let munis = {};
                municipalities.forEach((m) => 
                {
                    munis[m.id] = m;
                });

                candidates.forEach(c => 
                {
                    let m: Municipality = munis[c.municipality];

                    if(m)
                    {
                        results.push({
                            'candidate': c,
                            'muni': m
                        });
                    }
                    else
                    {
                        console.error('No municipality found for id ' + c.municipality);
                    }
                });

                results.sort((a, b) => 
                {
                    if(a.muni.name < b.muni.name) { return -1; }
                    else if(a.muni.name > b.muni.name) { return 1; }
                    else {
                        if(a.muni.type < b.muni.type) { return -1; }
                        else if(a.muni.type > b.muni.type) { return 1; }
                        else { return 0; }
                    }
                });

                results.forEach((r) => 
                {
                    let m = r.muni;
                    let c = r.candidate;

                    let electedString = m.resultsDeclared ? Candidate.getElectedStatusString(c.elected) : '';
                    let votes = m.resultsDeclared ? c.votes || '' : '';
                    let order = c.order || '';
                    let ward = '';

                    if(c.position === "Councillor" || c.position === "LUD")
                        ward = c.ward;

                    content += `"${c.firstName}","${c.lastName}","${c.gender}","${c.position}","${electedString}","${votes}","${c.previouslyElected}","${c.previouslyServed}","${order}","${ward}",`;
                    content += `"${m.name}","${m.district}","${m.type}","${m.resultsDeclared}"\n`;
                });
        
                this.downloadFile('candidates.csv', content);
            });
        });
    }

    private downloadFile(filename, content)
    {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(content));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
}

export function MunicipalitiesTabWithContext(props) {
    return (
        <UserContext.Consumer>
            {user => <MunicipalitiesTab currentUser={user} {...props} />}
        </UserContext.Consumer>
    );
}