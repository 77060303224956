import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Account } from "../dto/Account";
import { AMMDataService } from "../api/AMMDataService";
import { DialogSpinner } from "../components/DialogSpinner";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

export interface AccountChangeEmailDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onReset?:() => void;

    account: Account;
}

export interface AccountChangeEmailDialogState 
{
    isLoading: boolean;
    email: string;
}

export class AccountChangeEmailDialog extends React.Component<AccountChangeEmailDialogProps, AccountChangeEmailDialogState>
{
    constructor(props: AccountChangeEmailDialogProps)
    {
        super(props);

        this.state = {
            email: props.account ? props.account.email : '',
            isLoading: false
        };
    }

    public componentDidUpdate(prevProps: AccountChangeEmailDialogProps, prevState: AccountChangeEmailDialogState)
    {
        if(this.props.account && (!prevProps.account || prevProps.account !== this.props.account))
        {
            this.setState({ email: this.props.account.email });
        }
    }

    public handlePasswordReset(e)
    {
        this.setState({ isLoading: true });

        AMMDataService.current().changeEmail(this.props.account.uid, this.state.email).then(() => 
        {
            this.setState({ isLoading: false });

            if(this.props.onReset)
                this.props.onReset();
            else
                this.props.onClose();
        });

        e.preventDefault();
    }

    public render() 
    {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogSpinner showSpinner={this.state.isLoading} message="Changing email address. Please wait...">
                    <form onSubmit={this.handlePasswordReset.bind(this)} autoComplete="off">
                        <DialogTitle id="alert-dialog-title">{`Change email for ${this.props.account ? this.props.account.name : ''}`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {`This will disable the SEO's current email address and password. A new password will be generated and sent to the new email address. The SEO's previous password will become unusable.`}
                            </DialogContentText>

                            <FormControl fullWidth margin="normal">
                                <TextField
                                    required
                                    autoFocus
                                    fullWidth
                                    autoComplete="off"
                                    label="Email Address"
                                    name="newEmail"
                                    placeholder="Enter email address"
                                    type="text"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    disabled={this.state.isLoading}
                                    margin="normal" />
                            </FormControl>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleOnClose.bind(this)} color="default" autoFocus>Cancel</Button>
                            <Button type="submit" color="primary" variant="contained">Save and Reset Password</Button>
                        </DialogActions>
                    </form>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }
}
