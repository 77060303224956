import { Component } from "react";

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AMMDataService } from "../api/AMMDataService";
import { Municipality } from "../dto/Municipality";
import { RouteComponentProps } from "react-router";
import { RoleTrim } from "../components/RoleTrim";
import { MunicipalityDialog } from "../dialogs/MunicipalityDialog";
import { Candidate } from "../dto/Candidate";
import { CandidateDialog } from "../dialogs/CandidateDialog";
import { CandidateDeleteDialog } from "../dialogs/CandidateDeleteDialog";
import { WardDialog } from "../dialogs/WardDialog";
import { WardDeleteDialog } from "../dialogs/WardDeleteDialog";
import { Breadcrumb } from "../components/Breadcrumb";
import { BreadcrumbLink } from "../components/BreadcrumbLink";
import Ward from "../dto/Ward";
import Utils from "../utils/Utils";

export interface MunicipalityDetailsProps extends RouteComponentProps<any>
{
    
}

export interface MunicipalityDetailsState
{
    municipality: Municipality;
    candidates: Candidate[];
    selectedCandidate: Candidate;
    selectedWard: Ward;

    editDialogOpen: boolean;
    candidateDialogOpen: boolean;
    deleteCandidateOpen: boolean;
    deleteWardOpen: boolean;
    wardDialogOpen: boolean;
    isLoading: boolean;

    headCandidates: Candidate[];
    wards: {[key: string]: Candidate[]};
}

export class MunicipalityDetails extends Component<MunicipalityDetailsProps, MunicipalityDetailsState>
{
    constructor(props: MunicipalityDetailsProps) 
    {
        super(props);

        this.state = {
            municipality: null,
            candidates: [],
            headCandidates: [],
            editDialogOpen: false,
            candidateDialogOpen: false,
            wardDialogOpen: false,
            deleteWardOpen: false,
            deleteCandidateOpen: false,
            selectedCandidate: null,
            selectedWard: null,
            isLoading: false,
            wards: {}
        };
    }

    public componentDidMount() 
    {
        this.refresh();
    }

    public componentWillUnmount() 
    {

    }

    public refresh()
    {
        const muniId = this.props.match.params['municipalityId'];
        if(muniId)
        {
            this.setState({ isLoading: true });

            const muniPromise = AMMDataService.current().getMunicipality(muniId).then((municipality) => 
            {
                this.setState({ municipality: municipality });
            });

            const candidatePromise = AMMDataService.current().getCandidatesForMunicipality(muniId).then((candidates) => 
            {
                const headCandidates = candidates.filter((c) => 
                { 
                    const pos = c.position.toLowerCase();
                    return pos === 'mayor' || pos === 'reeve' || pos === 'head of council';
                });

                const councilCandidates = candidates.filter((c) => 
                {
                    return headCandidates.indexOf(c) === -1;
                });

                const wards: {[key: string]: Candidate[]} = {};
                councilCandidates.forEach(c => 
                {
                    const a: Candidate[] = wards[c.ward] || [];
                    a.push(c);
                    wards[c.ward] = a;
                });

                this.setState({ 
                    candidates: candidates,
                    headCandidates: headCandidates,
                    wards: wards
                 });
            });

            Promise.all([muniPromise, candidatePromise]).then((results) => 
            {
                this.setState({ isLoading: false });
            });
        }
    }

    public render() 
    {
        let name = '';
        let type = '';
        let district = '';
        let targetResults = '';
        let resultsDeclared = false;
        let wards = [];

        if(this.state.municipality)
        {   
            let muni = this.state.municipality;

            name = muni.name;
            type = muni.type;
            district = muni.district;
            targetResults = muni.targetResults;
            resultsDeclared = muni.resultsDeclared;

            wards = this.state.municipality.wards.sort(Utils.sortWards).map((w) => 
            {
                return (
                    <TableRow key={w.name}>
                        <TableCell component="th" scope="row">{w.name}</TableCell>
                        <TableCell>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => { this.setState({ wardDialogOpen: true, selectedWard: w }) }}>
                                Edit
                            </Button>
                            <Button 
                                size="small" 
                                variant="outlined" 
                                onClick={() => { this.setState({ deleteWardOpen: true, selectedWard: w }); }}
                                style={{ marginLeft: '10px' }}>
                                Delete
                            </Button>
                        </TableCell>
                    </TableRow>);
            });
        }
        
        return (
            <div className="tab-municipality-details">
                <Breadcrumb>
                    <BreadcrumbLink href="/admin/municipalities">Municipality List</BreadcrumbLink>
                    <span className="divider">/</span>
                    <BreadcrumbLink>{name}</BreadcrumbLink>
                </Breadcrumb>

                { name ? 
                    <h1>{name}</h1> :
                    <div className="placeholder h1"></div>
                }

                <div className="columns">

                    <div className="left-column">
                        <Paper>

                            <h3>Details</h3>

                            <RoleTrim allowedRoles="admin">
                                <Button 
                                    onClick={() => { this.setState({ editDialogOpen: true }); }}
                                    variant="contained" 
                                    disabled={this.state.isLoading}
                                    color="primary">
                                    Edit Municipality
                                </Button>
                            </RoleTrim>

                            <div className="account-details">
                                <div><label>Type</label><p>{type}</p></div>
                                <div><label>District</label><p>{district}</p></div>
                            </div>

                        </Paper>

                        <Paper>
                            <h3>Candidates</h3>
                            <p>Candidates list may be updated until election day (October 26, 2022).</p>
                            <Button 
                                onClick={() => { this.setState({ selectedCandidate: null, candidateDialogOpen: true }); }}
                                variant="contained" 
                                disabled={this.state.isLoading}
                                color="primary">
                                Add Candidate
                            </Button>

                            {this.generateHeadCandidateSection(this.state.headCandidates)}

                            {this.generateWardSections(this.state.wards)}

                        </Paper>
                    </div>

                    <div className="right-column">
                        <Paper>
                            <h3>Voting Results</h3>

                            {
                                resultsDeclared &&
                                <div className="count-status declared">Results Declared</div>
                            }

                            <RoleTrim allowedRoles="admin">
                                <p>View and update the current ballot count for this municipality.</p>
                                <Button 
                                    onClick={e => this.props.history.push(`${this.props.match.url}/results`)}
                                    variant="contained" 
                                    disabled={this.state.isLoading}
                                    color="primary">
                                    Update Results
                                </Button>
                                {
                                    resultsDeclared && 
                                    <Button 
                                        onClick={e => this.undoDeclaration()}
                                        variant="flat" 
                                        style={{ marginLeft: '10px' }}
                                        disabled={this.state.isLoading}
                                        color="primary">
                                        Undo Declaration (Admin)
                                    </Button>
                                }

                            </RoleTrim>

                            <RoleTrim allowedRoles="seo">
                                <p>View and update the current ballot count for this municipality.</p>
                                <Button 
                                    onClick={e => this.props.history.push(`${this.props.match.url}/results`)}
                                    variant="contained" 
                                    disabled={this.state.isLoading}
                                    color="primary">
                                    Update Results
                                </Button>
                            </RoleTrim>

                        </Paper>

                        <Paper>
                            <h3>Wards / LUDs</h3>
                            <Button
                                variant="contained"
                                disabled={this.state.isLoading}
                                onClick={() => { this.setState({ wardDialogOpen: true, selectedWard: null }); }}
                                color="primary">
                                Add Ward/LUD
                            </Button>

                            <Table style={{ marginTop: '10px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell style={{ width: '138px' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {wards}
                                </TableBody>
                            </Table>
                        </Paper>
                    </div>

                </div>

                <MunicipalityDialog 
                    municipality={this.state.municipality}
                    isOpen={this.state.editDialogOpen}
                    onSaved={() => { this.refresh(); this.setState({ editDialogOpen: false }); }}
                    onClose={() => { this.setState({ editDialogOpen: false })}} />

                <CandidateDialog
                    candidate={this.state.selectedCandidate}
                    municipality={this.state.municipality}
                    isOpen={this.state.candidateDialogOpen}
                    onSaved={() => { this.refresh(); this.setState({ candidateDialogOpen: false }); }}
                    onClose={() => { this.setState({ candidateDialogOpen: false }); }} />

                <CandidateDeleteDialog
                    candidate={this.state.selectedCandidate}
                    isOpen={this.state.deleteCandidateOpen}
                    onDeleted={() => { this.refresh(); this.setState({ deleteCandidateOpen: false }); }}
                    onClose={() => { this.setState({ deleteCandidateOpen: false }); }} />

                <WardDialog
                    municipality={this.state.municipality}
                    wardName={this.state.selectedWard ? this.state.selectedWard.name : ''}
                    wardNameFr={this.state.selectedWard ? this.state.selectedWard.frName : ''}
                    wardOrder={this.state.selectedWard ? this.state.selectedWard.order : null }
                    isOpen={this.state.wardDialogOpen}
                    onSaved={() => { this.refresh(); this.setState({ wardDialogOpen: false }); }}
                    onClose={() => { this.setState({ wardDialogOpen: false }); }} />

                <WardDeleteDialog
                    municipality={this.state.municipality}
                    wardName={this.state.selectedWard ? this.state.selectedWard.name : ''}
                    isOpen={this.state.deleteWardOpen}
                    onClose={() => { this.setState({ deleteWardOpen: false }); }}
                    onDeleted={() => { this.refresh(); this.setState({ deleteWardOpen: false }); }} />

            </div>
        );
    }

    private generateHeadCandidateSection(candidates: Candidate[])
    {
        candidates.sort(Candidate.sort);

        return (
            <div style={{ marginTop: '40px' }}>
                <h3>Mayor / Reeve / Head of Council</h3>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Gender</TableCell>
                            <TableCell>Position</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { candidates.map((d) => {
                            return (
                                <TableRow key={d.id}>
                                    <TableCell component="th" scope="row">{Candidate.fullName(d)}</TableCell>
                                    <TableCell>{d.gender}</TableCell>
                                    <TableCell>{d.position}</TableCell>
                                    <TableCell>
                                        <Button size="small" variant="outlined" onClick={() => { this.setState({ selectedCandidate: d, candidateDialogOpen: true }); }}>Edit</Button>
                                        <Button size="small" variant="outlined" onClick={() => { this.setState({ selectedCandidate: d, deleteCandidateOpen: true })}} style={{ marginLeft: '10px'}}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>);
                            })
                        }

                        {
                            candidates.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <p style={{marginBottom: 0, textAlign: 'center', color: 'rgba(0, 0, 0, 0.54)'}}>No Candidates</p>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
        );
    }

    private generateWardSections(wards: {[key: string]: Candidate[]})
    {
        const retval = [];
        let sortedWardNames: { name: string, order: number }[] = [];

        for(const ward in wards)
        {
            if(wards.hasOwnProperty(ward))
                sortedWardNames.push({ name: ward, order: null });
        }

        if(this.state.municipality)
        {
            // sortedWardNames = this.state.municipality.wards
            //     .filter(w => sortedWardNames.includes(w.name) || 'At Large')
            //     .sort(Utils.sortWards)
            //     .map(w => w.name);

            sortedWardNames.forEach(sw => {
                const mWard = this.state.municipality.wards.find(w => w.name === sw.name);
                if(sw.name === 'At Large')
                    sw.order = 0;
                else
                    sw.order = mWard?.order ?? null;
            });

            sortedWardNames.sort(Utils.sortByProperties([{ property: 'order', direction: 'asc' }, { property: 'name', direction: 'asc' }]));
        }

        sortedWardNames.forEach(ward => 
        {
            const candidates: Candidate[] = wards[ward.name];
            candidates.sort(Candidate.sort);

            retval.push(
                <div key={ward.name} style={{ marginTop: '40px' }}>
                    <h3>Councillor Candidates - {ward.name}</h3>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { candidates.map((d) => {
                                return (
                                    <TableRow key={d.id}>
                                        <TableCell component="th" scope="row">{Candidate.fullName(d)}</TableCell>
                                        <TableCell>{d.gender}</TableCell>
                                        <TableCell>{d.position}</TableCell>
                                        <TableCell>
                                            <Button size="small" variant="outlined" onClick={() => { this.setState({ selectedCandidate: d, candidateDialogOpen: true }); }}>Edit</Button>
                                            <Button size="small" variant="outlined" onClick={() => { this.setState({ selectedCandidate: d, deleteCandidateOpen: true })}} style={{ marginLeft: '10px'}}>
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>);
                                })
                            }
                        </TableBody>
                    </Table>
                </div>
            );
        });

        if(sortedWardNames.length === 0)
        {
            retval.push(
                <div key={0} style={{marginTop: '40px'}}>
                    <h3>Councillor Candidates</h3>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <p style={{marginBottom: 0, textAlign: 'center', color: 'rgba(0, 0, 0, 0.54)'}}>No Candidates</p>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            );
        }

        return retval;
    }

    private undoDeclaration()
    {
        let muni = this.state.municipality;
        this.setState({ isLoading: true });

        AMMDataService.current().updateMunicipality(muni.id, { resultsDeclared: false }).then((r) => 
        {
            this.refresh();
        }).catch((error) => 
        {
            this.setState({ isLoading: false });
            alert('Error updating declaration status: ' + error);
        });
    }
}
