
export default class AMMError extends Error
{
    code: string;
    status: number;

    constructor(code: string, message: string, status: number)
    {
        super(message);
        this.name = 'amm.api_error';
        this.code = code;
        this.status = status;
    }
}