import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import AMMError from "./AMMError";

export default class AMMQuery<T>
{
    public readonly resourceUrl: string;
    //private authInitialized: boolean;
    //private idToken: string;

    constructor(resourceUrl: string)
    {
        this.resourceUrl = resourceUrl;
        //this.authInitialized = false;
        //this.idToken = null;

        // const auth = getAuth();
        // onAuthStateChanged(auth, async (user) => 
        // {
        //     if(user)
        //         this.idToken = await user.getIdToken();
        //     this.authInitialized = true;
        // });
    }

    public async get(): Promise<T[]>
    {
        const response = await fetch(this.resourceUrl, await this.requestOptions('GET'));

        return this.handleResponse<T[]>(response);
    }

    public async getById(id: string): Promise<T>
    {
        const response = await fetch(`${this.resourceUrl}/${id}`, await this.requestOptions('GET'));

        return this.handleResponse<T>(response);
    }

    public async add(body: Partial<T>): Promise<T>
    {
        const response = await fetch(this.resourceUrl, await this.requestOptions('POST', body));

        return this.handleResponse<T>(response);
    }

    public async update(id: string, body: Partial<T>): Promise<T>
    {
        const response = await fetch(`${this.resourceUrl}/${id}`, await this.requestOptions('PATCH', body));

        return this.handleResponse<T>(response);
    }

    public async delete(id: string): Promise<T>
    {
        const response = await fetch(`${this.resourceUrl}/${id}`, await this.requestOptions('DELETE'));

        return this.handleResponse<T>(response);
    }

    public async action<K>(actionName: string, method: 'POST', body?: any)
    {
        const response = await fetch(`${this.resourceUrl}/actions/${actionName}`, await this.requestOptions(method, body));

        return this.handleResponse<K>(response);
    }

    public async actionById<K>(id: string, actionName: string, method: 'GET' | 'POST', body?: any)
    {
        const response = await fetch(`${this.resourceUrl}/${id}/actions/${actionName}`, await this.requestOptions(method, body));

        return this.handleResponse<K>(response);
    }

    private async handleResponse<T>(response: Response): Promise<T>
    {
        if(response.ok)
        {
            try {
                return await response.json();
            }
            catch(ex)
            {
                return null;
            }
        }
        else
        {
            const textResponse = response.clone();
            const jsonResponse = response.clone();

            let code = 'amm.unknown';
            let message = 'Unknown error';

            try {
                const json = await jsonResponse.json();
                code = json.code;
                message = json.message;
            }
            catch(ex) { }

            try
            {
                const text = await jsonResponse.text();
                message = text;
            }
            catch(ex) { }

            throw new AMMError(code, message, response.status);
        }
    }

    private async requestOptions(method: 'GET' | 'POST' | 'PATCH' | 'DELETE', body? : any)
    {
        let headers = {
            'content-type': 'application/json'
        };

        const auth = getAuth();
        if(auth.currentUser)
        {
            const token = await auth.currentUser.getIdToken();
            headers = Object.assign(headers, { 'x-access-token': `bearer ${token}` });
        }

        return {
            method,
            headers,
            body: body ? JSON.stringify(body) : undefined
        };
    }
}