import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Account } from "../dto/Account";
import { AMMDataService } from "../api/AMMDataService";
import { Municipality } from "../dto/Municipality";
import { DialogSpinner } from "../components/DialogSpinner";

export interface AccountRemoveMunicipalityDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onRemoved?:() => void;

    account: Account;
    selectedMunicipality: Municipality;
}

export interface AccountRemoveMunicipalityDialogState 
{
    isLoading: boolean;
}

export class AccountRemoveMunicipalityDialog extends React.Component<AccountRemoveMunicipalityDialogProps, AccountRemoveMunicipalityDialogState>
{
    constructor(props: AccountRemoveMunicipalityDialogProps)
    {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public handleOnDelete()
    {
        this.setState({ isLoading: true });

        if(this.props.selectedMunicipality)
        {
            AMMDataService.current().getMunicipality(this.props.selectedMunicipality.id).then((muni) => 
            {
                if(muni.assigned && muni.assigned[this.props.account.uid])
                {
                    delete muni.assigned[this.props.account.uid];

                    AMMDataService.current().updateMunicipality(this.props.selectedMunicipality.id, {
                        assigned: muni.assigned
                    }).then(() => 
                    {
                        this.setState({ isLoading: false });
                        if(this.props.onRemoved)
                            this.props.onRemoved();
                        else
                            this.props.onClose();
                    });
                }
                else
                {
                    this.setState({ isLoading: false });
                    this.props.onClose();
                }
            });
        }
    }

    public render() 
    {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogSpinner showSpinner={this.state.isLoading}>
                    <DialogTitle id="alert-dialog-title">{`Remove municipality from ${this.props.account ? this.props.account.name : ''}?`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Are you sure you want to remove this municipality?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleOnClose.bind(this)} color="default" autoFocus>Cancel</Button>
                        <Button onClick={this.handleOnDelete.bind(this)} color="primary" variant="contained" style={{ backgroundColor: '#f44336' }}>Remove</Button>
                    </DialogActions>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }
}
