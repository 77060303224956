import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

export interface DisclaimerDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
}

export interface DisclaimerDialogState 
{

}

export class DisclaimerDialog extends React.Component<DisclaimerDialogProps, DisclaimerDialogState>
{
    constructor(props: DisclaimerDialogProps)
    {
        super(props);

        this.state = {
        };
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public render() 
    {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Under Construction</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This site is still under active development and testing. The information available on this site is NOT final and subject to change. This site is intended to be launched for public consumption on October 1, 2022.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained" 
                        color="primary"
                        autoFocus
                        onClick={this.handleOnClose.bind(this)}>I understand</Button>
                </DialogActions>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        this.props.onClose();
    }
}
