import { Account } from "../dto/Account";
import { Municipality } from "../dto/Municipality";
import AMMQuery from "./AMMQuery";

export class UserQuery
{
    private query: AMMQuery<Account>;

    constructor(rootUrl?: string)
    {
        this.query = new AMMQuery<Account>((rootUrl ?? '') + `/users`);
    }

    // public add(data: Partial<Account>)
    // {
    //     return this.query.add(data);
    // }

    public createSEO(email: string, name: string)
    {
        return this.query.action<Account>('createSEO', 'POST', { email, name });
    }

    public get()
    {
        return this.query.get();
    }

    public getById(id: string)
    {
        return {
            update: (data: any) => { return this.update(id, data) },
            get: () => { return this.getOne(id); },
            //delete: () => { return this.delete(id); },
            getMunicipalities: () => { return this.getMunicipalities(id); },
            deleteSEO: () => { return this.deleteSEO(id); },
            changeEmailTo: (email: string) => { return this.changeEmailTo(id, email); },
            resetPassword: () => { return this.resetPassword(id); }
        };
    }

    private update(id: string, data: Partial<Account>)
    {
        return this.query.update(id, data);
    }

    private getOne(id: string)
    {
        return this.query.getById(id);
    }

    private deleteSEO(userId: string)
    {
        return this.query.actionById(userId, 'deleteSEO', 'POST');
    }

    private changeEmailTo(userId: string, email: string)
    {
        return this.query.actionById(userId, 'changeEmail', 'POST', { email });
    }

    private resetPassword(userId: string)
    {
        return this.query.actionById(userId, 'resetPassword', 'POST');
    }

    // private delete(id: string)
    // {
    //     return this.query.delete(id);
    // }

    private getMunicipalities(userId: string)
    {
        const newQuery = new AMMQuery<Municipality>(`${this.query.resourceUrl}/${userId}/municipalities`);
        return newQuery.get();
    }
}