import { Component } from "react";
import { Link } from 'react-router-dom';

export interface BreadcrumbLinkProps
{
    className?: string;
    href?: string;
}

export interface BreadcrumbLinkState
{
}

export class BreadcrumbLink extends Component<BreadcrumbLinkProps, BreadcrumbLinkState>
{
    constructor(props: BreadcrumbLinkProps)
    {
        super(props);

        this.state = {
        }
    }

    public componentDidMount()
    {
    }

    public render()
    {
        let additionalClasses = this.props.className;
        let retval = null;

        if(this.props.href)
        {
            return (
                <Link to={this.props.href} className={'component-breadcrumb-link' + (additionalClasses ? ` ${additionalClasses}`: '')}>{this.props.children}</Link>
            );
        }
        else
        {
            return (
                <span className={'component-breadcrumb-link' + (additionalClasses ? ` ${additionalClasses}`: '')}>{this.props.children}</span>
            );
        }
    }
}
