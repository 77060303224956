import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { TextField } from "@material-ui/core";
import { AMMDataService } from "../api/AMMDataService";
import { Account } from "../dto/Account";
import { DialogSpinner } from "../components/DialogSpinner";

enum DialogMode 
{
    New,
    Edit
};

export interface AccountDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onSaved?:() => void;
    onCreated?: (accountId: string) => void;

    account?: Account;
}

export interface AccountDialogState 
{
    email: string;
    name: string;
    role: string;
    password: string;
    mode: DialogMode;
    isLoading: boolean;
}

export class AccountDialog extends React.Component<AccountDialogProps, AccountDialogState>
{
    constructor(props: AccountDialogProps)
    {
        super(props);

        if(props.account)
        {
            let account = props.account;

            this.state = {
                mode: DialogMode.Edit,
                email: account.email,
                name: account.name,
                role: account.role,
                password: '********',
                isLoading: false
            };
        }
        else
        {
            this.state = {
                mode: DialogMode.New,
                email: '',
                name: '',
                role: '',
                password: '',
                isLoading: false
            };
        }
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public componentDidUpdate(prevProps: AccountDialogProps, prevState: AccountDialogState)
    {
        if(this.props.account && (!prevProps.account || prevProps.account !== this.props.account))
        {
            let account = this.props.account;
            this.setState({
                email: account.email,
                name: account.name,
                role: account.role,
                password: '********',
                mode: DialogMode.Edit
            });
        }
        else if(!this.props.account && prevProps.account)
        {
            this.setState({
                email: '',
                name: '',
                role: '',
                password: '',
                mode: DialogMode.New
            });
        }
    }

    public handleOnSave(e)
    {
        if(this.state.name && this.state.email)
        {
            if(this.state.mode === DialogMode.New)
            {
                // let account = new Account();
                // account.name = this.state.name;
                // account.email = this.state.email;
                // account.role = 'seo';

                // let password = this.state.password;

                this.setState({ isLoading: true });

                AMMDataService.current().createSEOAccount(this.state.email, this.state.name).then((newAccount) => 
                {
                    this.setState({ isLoading: false });

                    if(this.props.onCreated)
                        this.props.onCreated(newAccount.id);
                    else
                        this.props.onClose();
                });
            }
            else if(this.state.mode == DialogMode.Edit)
            {
                let updateData = {
                    name: this.state.name,
                    email: this.state.email
                };

                AMMDataService.current().updateAccount(this.props.account.uid, updateData).then(() => 
                {
                    this.setState({ isLoading: false });

                    if(this.props.onSaved)
                        this.props.onSaved();
                    else
                        this.props.onClose();
                });
            }
        }

        e.preventDefault();
    }

    public render() 
    {
        let title = '';
        let button = '';

        if(this.state.mode === DialogMode.New)
        {
            title = 'New SEO Account';
            button = 'Create';
        }
        else
        {
            title = 'Edit SEO Account';
            button = 'Save';
        }

        return (
            <Dialog
            open={this.props.isOpen}
            onClose={this.handleOnClose.bind(this)}
            scroll="paper"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
                <DialogSpinner showSpinner={this.state.isLoading} message="Creating account...">
                    <form onSubmit={this.handleOnSave.bind(this)} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                            <TextField
                                required
                                autoFocus
                                fullWidth
                                label="Email"
                                placeholder="Enter an email address"
                                type="email"
                                autoComplete="off"
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                disabled={this.state.isLoading}
                                margin="normal" />

                            <TextField
                                required
                                fullWidth
                                label="Name"
                                autoComplete="off"
                                value={this.state.name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                                placeholder="Enter a display name"
                                disabled={this.state.isLoading}
                                margin="normal">
                            </TextField>

                            <TextField
                                fullWidth
                                label="Password"
                                value="********"
                                helperText={this.state.mode === DialogMode.New ? "A password will be generated and sent to the new SEO account's email address." : null}
                                disabled
                                margin="normal">
                            </TextField>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleOnClose.bind(this)} color="default" disabled={this.state.isLoading}>Cancel</Button>
                        <Button type="submit" color="primary" variant="contained" disabled={this.state.isLoading}>{button}</Button>
                    </DialogActions>
                    </form>
                </DialogSpinner>
            </Dialog>
        );
    }

    // private generatePassword() 
    // {
    //     let length = 10;
    //     let charset = "abcdefghijkmnopqrstuvwxyzACDEFGHJKLMNPQRSTUVWXYZ2345679";
    //     let retVal = "";

    //     for (var i = 0, n = charset.length; i < length; ++i) {
    //         retVal += charset.charAt(Math.floor(Math.random() * n));
    //     }
    //     return retVal;
    // }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }
}
