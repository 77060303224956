//import { getFirestore, Firestore, collection, addDoc, updateDoc, deleteDoc, doc, getDoc } from "firebase/firestore";
import { Municipality } from "../dto/Municipality";
import { Account } from "../dto/Account";
import { Candidate } from "../dto/Candidate";

//import firebase from "firebase/compat/app";
//import 'firebase/compat/firestore';
import { MunicipalityQuery } from "./MunicipalityQuery";
import { CandidatesQuery } from "./CandidateQuery";
import { UserQuery } from "./UserQuery";
import Utils from "../utils/Utils";

export class AMMDataService
{
    private static _current: AMMDataService = null;
    //private db: firebase.firestore.Firestore = null;

    public static current(): AMMDataService
    {
        if(!this._current)
        {
            this._current = new AMMDataService();
        }

        return this._current;
    }

    public municipalities: MunicipalityQuery;
    public candidates: CandidatesQuery;
    public users: UserQuery;

    constructor()
    {
        //this.db = firebase.firestore();

        const apiUrl = process.env['REACT_APP_API_URL'];

        this.municipalities = new MunicipalityQuery(apiUrl);
        this.candidates = new CandidatesQuery(apiUrl);
        this.users = new UserQuery(apiUrl);
    }

    // -------------------------
    // Municipality Functions
    // -------------------------

    public createMunicipality(municipality: Municipality)
    {
        return this.municipalities.add(municipality);

        // // Required due to firebase not liking custom class objects
        // let obj = JSON.parse(JSON.stringify(municipality));

        // //return addDoc(collection(this.db, 'municipalities'), obj);

        // return this.db.collection('municipalities').add(obj).then((docRef) => 
        // {
        //     return docRef.id;
        // });
    }

    public updateMunicipality(id: string, updateData: Partial<Municipality>)
    {
        return this.municipalities.getById(id).update(updateData);
        //return this.db.collection('municipalities').doc(id).update(updateData);
    }

    public async getMunicipality(id: string)
    {
        return this.municipalities.getById(id).get();

        // return this.db.collection('municipalities').doc(id).get().then((docSnapshot) => 
        // {
        //     return Municipality.fromDoc(docSnapshot);
        // });
    }

    public getMunicipalitiesForSEO(uid: string)
    {
        return this.users.getById(uid).getMunicipalities();

        // return this.db.collection('municipalities')
        //     .where('assigned.' + uid, '==', true)
        //     .get().then((querySnapshot) => 
        // {
        //     let retval: Municipality[] = [];
        //     querySnapshot.forEach((d) => 
        //     {
        //         retval.push(Municipality.fromDoc(d));
        //     });

        //     return retval.sort(this.sortMunicipalities);
        // });
    }

    public getMunicipalities()
    {
        return this.municipalities.get();

        // return this.db.collection('municipalities')
        //     .orderBy('name')
        //     .get().then((querySnapshot) => 
        // {
        //     let retval: Municipality[] = [];
        //     querySnapshot.forEach((d) => 
        //     {
        //         retval.push(Municipality.fromDoc(d));
        //     });

        //     return retval;
        // });
    }

    public deleteMunicipality(id: string)
    {
        return this.municipalities.getById(id).delete();
        //return this.db.collection('municipalities').doc(id).delete();
    }

    // -------------------------
    // Candidate Functions
    // -------------------------

    public createCandidate(candidate: Candidate)
    {
        return this.candidates.add(candidate);

        // // Required due to firebase not liking custom class objects
        // let obj = JSON.parse(JSON.stringify(candidate));

        // return this.db.collection('candidates').add(obj).then((docRef) => 
        // {
        //     return docRef.id;
        // });
    }

    public updateCandidate(id: string, updateData: any)
    {
        return this.candidates.getById(id).update(updateData);
        //return this.db.collection('candidates').doc(id).update(updateData);
    }

    public deleteCandidate(id: string)
    {
        return this.candidates.getById(id).delete();
        //return this.db.collection('candidates').doc(id).delete();
    }

    public getCandidatesForMunicipality(municipalityId: string)
    {
        return this.municipalities.getById(municipalityId).candidates.get();

        // return this.db.collection('candidates')
        //     .where('municipality', '==', municipalityId)
        //     .get().then((querySnapshot) => 
        // {
        //     let retval: Candidate[] = [];
        //     querySnapshot.forEach((d) => 
        //     {
        //         retval.push(Candidate.fromDoc(d));
        //     });

        //     return retval;
        // });
    }

    public getCandidates()
    {
        return this.candidates.get();
        // return this.db.collection('candidates')
        //     .get().then((querySnapshot) => 
        // {
        //     let retval: Candidate[] = [];
        //     querySnapshot.forEach((d) => 
        //     {
        //         retval.push(Candidate.fromDoc(d));
        //     });

        //     return retval;
        // });
    }

    // -------------------------
    // SEO Account Functions
    // -------------------------

    public getUserInfo(uid: string)
    {
        return this.users.getById(uid).get();

        // return this.db.collection('users')
        //     .where('uid', '==', uid)
        //     .get().then((querySnapshot) => 
        // {
        //     let retval: Account = null;
        //     querySnapshot.forEach((doc) => 
        //     {
        //         retval = Account.fromDoc(doc);
        //     });
        //     return retval;
        // });
    }

    public async getSEOAccounts()
    {
        const allUsers = await this.users.get();

        return allUsers
            .filter(u => u.role === 'seo')
            .sort(Utils.sortByProperty('name'));

        // return this.db.collection('users')
        //     .where('role', '==', 'seo')
        //     .orderBy('name')
        //     .get().then((querySnapshot) => 
        // {
        //     let retval: Account[] = [];
        //     querySnapshot.forEach((d) => 
        //     {
        //         retval.push(Account.fromDoc(d));
        //     });

        //     return retval;
        // });
    }

    public getSEOAccount(id: string)
    {
        return this.users.getById(id).get();

        // return this.db.collection('users').doc(id).get().then((docSnapshot) => 
        // {
        //     return Account.fromDoc(docSnapshot);
        // });
    }

    //public createAccount(account: Account, password: string)
    public createSEOAccount(email: string, name: string)
    {
        return this.users.createSEO(email, name);

        // const userInfo = {
        //     email: account.email,
        //     name: account.name,
        //     password: password,
        //     role: account.role
        // };

        // let createSEO = firebase.functions().httpsCallable('createSEO');
        // return createSEO(userInfo).then((result) => 
        // {
        //     console.log(`Created account ${result.data.uid}`);
        //     return result.data.uid;
            
        // }).catch((response) => 
        // {
        //     //console.error(`Error creating account: ${response}`);
        //     // {
        //     //     code: 'permission-denied',
        //     //     details: undefined,
        //     //     message: 'You must be an admin to create an SEO account',
        //     //     stack: '....stacktrace...'
        //     // }
        //     return response; 
        // });
    }

    public updateAccount(uid: string, updateData: any)
    {
        return this.users.getById(uid).update(updateData);

        // return this.db.collection('users').doc(uid)
        //     .get().then((docSnapshot) => 
        // {
        //     if(docSnapshot.exists)
        //     {
        //         docSnapshot.ref.update(updateData).then(() => 
        //         {

        //         });
        //     }
        // });
    }

    public deleteAccount(uid: string)
    {        
        return this.users.getById(uid).deleteSEO();

        // let deleteSEO = firebase.functions().httpsCallable('deleteSEO');
        // return deleteSEO({ uid: uid }).then((response) => 
        // {
        //     //console.log(`Deleted account ${result.data}`);

        //     return response;
        // });
    }

    public changeEmail(uid: string, email: string)
    {
        return this.users.getById(uid).changeEmailTo(email);

        // let changeEmail = firebase.functions().httpsCallable('changeEmail');
        // return changeEmail({ uid: uid, email: email }).then((response) => 
        // {
        //     return response;
        // });
    }

    public resetPassword(uid: string)
    {
        return this.users.getById(uid).resetPassword();

        // let resetPassword = firebase.functions().httpsCallable('resetPassword');
        // return resetPassword({ uid: uid }).then((response) => 
        // {
        //     return response;
        // });
    }

    // -------------------------
    // Convenience Functions
    // -------------------------

    // private getDocRef(collectionName: string, id: string)
    // {
    //     return doc(collection(this.db, collectionName), id);
    // }

    // private sortMunicipalities(a: Municipality, b: Municipality)
    // {
    //     if(a.name < b.name) { return -1 }
    //     else if(a.name > b.name) {  return 1; }
    //     else return 0;
    // }
}