import { Component } from "react";
import { Footer } from "../components/Footer";
import { FirebaseError } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, User } from "firebase/auth";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RouteComponentProps } from "react-router-dom";

export interface LoginProps extends RouteComponentProps<any>
{
}

export interface LoginState 
{
    email: string;
    password: string;
    errorMessage: string;
    isSubmitting: boolean;
    isLoading: boolean;
}

export class Login extends Component<LoginProps, LoginState>
{
    constructor(props: LoginProps) 
    {
        super(props);

        this.state = {
            email: '',
            password: '',
            errorMessage: '',
            isSubmitting: false,
            isLoading: true
        };
    }

    public componentDidMount() 
    {
        const auth = getAuth();
        onAuthStateChanged(auth, (user: User) => 
        {
            if(user)
            {
                user.getIdToken(true).then((token) =>
                {
                    this.props.history.replace('/admin');
                });
            }
            else
            {
                // Hide loading spinner if user is not logged in and show login form
                this.setState({ isLoading: false });
            }
        });
    }

    public onLogin(e)
    {
        if(this.state.isSubmitting === false)
        {
            this.setState({ isSubmitting: true, errorMessage: null });

            const auth = getAuth();
            signInWithEmailAndPassword(auth, this.state.email, this.state.password)
                .catch((error: FirebaseError) =>
            {
                let message = 'Error: ' + error.code + ': ' + error.message;
                let emailError = null;

                if(error.code === 'auth/user-not-found'
                    || error.code === 'auth/wrong-password')
                {
                    message = 'Email or password is incorrect';
                }
                else if(error.code === 'auth/invalid-email')
                {
                    message = 'Please enter a valid email address';
                }
                else if(error.code === 'auth/user-disabled')
                {
                    message = 'This account has been disabled'
                }

                this.setState({
                    errorMessage: message,
                    isSubmitting: false,
                    password: ''
                });
            });   
        }

        e.preventDefault();
    }

    public componentWillUnmount() 
    {

    }

    public render() 
    {
        let formContent = null;

        if(this.state.isLoading === false)
        {
            let errorContent = null;

            if(this.state.errorMessage)
            {
                errorContent = (
                    <p className="error-message">{this.state.errorMessage}</p>
                );
            }

            formContent = (
                <form onSubmit={this.onLogin.bind(this)}>
                    <TextField
                        disabled={this.state.isSubmitting} 
                        autoFocus
                        fullWidth
                        label="Email"
                        name="email"
                        placeholder="Enter your email address"
                        type="email"
                        margin="dense"
                        onChange={this.handleEmailChange.bind(this)}
                    />

                    <TextField
                        disabled={this.state.isSubmitting}
                        fullWidth
                        label="Password"
                        name="password"
                        placeholder="Enter your password"
                        type="password"
                        margin="dense"
                        onChange={this.handlePasswordChange.bind(this)}
                    />

                    {errorContent}

                    <div className="login-button-container">

                        <Button
                            disabled={this.state.isSubmitting}
                            variant="contained" 
                            color="primary"
                            type="submit">
                            Log In
                        </Button>

                        <a className="forgot" href="/admin/forgot">Forgot Password?</a>

                    </div>

                    <div className="info-container">
                        <p>Not an Admin or SEO? <a href="/">Go back to the Homepage</a></p>
                    </div>
                    
                </form>
            );
        }
        else
        {
            formContent = (
                <div className="form-loading">
                    <CircularProgress color="secondary" />
                    <div>Signing in</div>
                </div>
            );
        }

        return (
            <div className="page-login">

                <div className="card-login">
                    <div className="logo-container">
                        <img src="/images/amm.png" />
                    </div>

                    <h1>AMM Administrator<br /> and SEO Login</h1>

                    {formContent}

                </div>

                <Footer />
            </div>
        );
    }

    public handleEmailChange(event)
    {
        this.setState({ email: event.target.value });
    }

    public handlePasswordChange(event)
    {
        this.setState({ password: event.target.value });
    }
}
