import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resourceFile = process.env['REACT_APP_LANGUAGE_RESOURCE'] || 'i18n-2022.json';
const resources = require(`./${resourceFile}`);

// lng option overrides Language Detector
// Only set lng if user has explicitly selected a language
//let defaultLanguage = undefined;
// if(window.localStorage)
// {
//     const savedLanguage = window.localStorage.getItem('language');
//     if(savedLanguage === 'en' || savedLanguage === 'fr')
//         defaultLanguage = savedLanguage;
// }

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        //lng: defaultLanguage,
        resources,
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;