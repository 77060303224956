import * as React from "react";

import Dialog from '@material-ui/core/Dialog';
import { DialogSpinner } from "../components/DialogSpinner";
import DialogTitle from "../../node_modules/@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "../../node_modules/@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "../../node_modules/@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "../../node_modules/@material-ui/core/DialogActions/DialogActions";
import Button from "../../node_modules/@material-ui/core/Button/Button";

export interface DeclareResultsDialogProps
{
    isOpen: boolean;
    onSave: () => void;
    onClose: () => void;
}

export interface DeclareResultsDialogState 
{
    isLoading: boolean;
}

export class DeclareResultsDialog extends React.Component<DeclareResultsDialogProps, DeclareResultsDialogState>
{
    constructor(props: DeclareResultsDialogProps)
    {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }
    
    public render() 
    {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogSpinner showSpinner={this.state.isLoading} message="Saving results. Please wait...">
                    <DialogTitle id="alert-dialog-title">Confirm Declare Results</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {`Declaring the results will make the current vote count visible to the general public. Do you want to continue?`}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleOnClose.bind(this)} color="default" autoFocus>Cancel</Button>
                            <Button onClick={this.handleOnSave.bind(this)} color="primary" variant="contained">Declare Results</Button>
                        </DialogActions>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnSave()
    {
        this.setState({ isLoading: true });

        if(this.props.onSave)
        {
            this.props.onSave();
        }
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }
}
