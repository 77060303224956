import { Component } from "react";
import { Municipality } from '../dto/Municipality';
import Button from '@material-ui/core/Button';

export interface MunicipalityListItemProps
{
    className?: string;
    municipality?: Municipality;
    active?: boolean;

    onClick?: (e) => void;
}

export interface MunicipalityListItemState
{

}

export class MunicipalityListItem extends Component<MunicipalityListItemProps, MunicipalityListItemState>
{
    constructor(props: MunicipalityListItemProps)
    {
        super(props);

        this.state = {
        };
    }

    public componentDidMount()
    {
    }

    public componentWillUnmount()
    {
        
    }

    public render()
    {
        let name = 'Unnamed';
        let type = '';
        let statusClass = '';

        if(this.props.municipality)
        {
            name = this.props.municipality.getName();
            type = this.props.municipality.getTypeString();
            statusClass = this.props.municipality.resultsDeclared ? 'declared' : 'counting';
        }

        return (
            <Button 
                fullWidth 
                onClick={this.onClick.bind(this)}
                variant="outlined" 
                className={'municipality-listitem-button' + (this.props.className ? ' ' + this.props.className : '')}>
                <div className="component-municipality-listitem">
                    <div className="item-status">
                        <div className={statusClass}></div>
                    </div>
                    <div className="item-info">
                        <div className="item-title">{name}</div>
                        <div className="item-detail">{type}</div>
                    </div>
                    <div className="item-arrow">
                        <i className="fa fa-chevron-right" />
                    </div>
                </div>
            </Button>
        );
    }

    private onClick(e)
    {
        if(this.props.onClick)
            this.props.onClick(e);
    }
}