import { Component } from "react";
import i18n from "../utils/i18n";

export interface FooterProps
{
    className?: string;
    variant?: 'default' | 'light';
}

export interface FooterState
{
}

export class Footer extends Component<FooterProps, FooterState>
{
    constructor(props: FooterProps)
    {
        super(props);

        this.state = {
        }
    }

    public componentDidMount()
    {
        
    }

    public render()
    {
        let bsLogo = '/images/bloomsoftware-green.svg';
        let ambmLogo = '/images/ambm.svg';
        let ammLogo = '/images/amm.png';

        let additionalClasses = this.props.className || '';
        if(this.props.variant === 'light')
        {
            additionalClasses += ' light';
            bsLogo = '/images/bloomsoftware-white.svg';
            ambmLogo = '/images/ambm-white.svg';
            ammLogo = '/images/amm-white.png';
        }

        return (
            <footer className={'component-footer' + (additionalClasses ? ` ${additionalClasses}`: '')}>
                <div className="footer-section amm">
                    {/* <a href="http://www.amm.mb.ca/"><img src="/images/amm.png" /></a> */}
                    {/* <div className="separator"></div> */}
                    {/* <div>Brought to you by<br />The Association of Manitoba Municipalities</div> */}

                    <div className="footer-logos">
                        <a href="https://ambm.ca/"><img src={ambmLogo} alt="Association of Manitoba Bilingual Municipalities Logo" /></a>
                        <a href="http://www.amm.mb.ca/"><img src={ammLogo} alt="Association of Manitoba Municipalities Logo" /></a>
                        <a href="https://www.bloomsoftwareco.com" className="bsi-logo"><img src={bsLogo} alt="Bloom Software Logo" /></a>
                    </div>

                    <div className="footer-credits">
                        <div>{i18n.t('Copyright')}</div>
                        <div>{i18n.t('AMBM-Credit')}</div>
                    </div>

                </div>
                <div className="footer-section blueshift">
                    <a href="https://www.bloomsoftwareco.com"><img src={bsLogo} alt="Bloom Software Logo" /></a>
                    <div className="separator"></div>
                    <div>{i18n.t('PoweredBy')}<br />Bloom Software</div>
                </div>
            </footer>
        );
    }
}
