import { render } from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import { App } from "./App";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import reportWebVitals from './reportWebVitals';

import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";

const theme = createMuiTheme({
    typography: {
        htmlFontSize: 10
    },
    palette: {
        primary: {
            main: '#193F72'
        },
        secondary: {
            main: '#4EAB42'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
                padding: '6px 16px'
            }
        },
        MuiPaper: {
            root: {
                padding: '20px',
                marginBottom: '20px'
            },
            elevation8: {
                padding: '0'
            }
        },
        MuiTableCell: {
            root: {
                fontSize: '1.6rem !important'
            }
        },
        MuiTab: {
            label: {
                fontSize: '1.6rem !important'
            }
        },
        MuiDialog: {
            paper: {
                minWidth: '220px',
                padding: '0',
                margin: '20px'
            }
        },
        MuiDialogActions: {
            root: {
                margin: '24px 12px'
            }
        }
    }
});

const firebaseConfig = {
    apiKey: "AIzaSyBmwHFQuR4VM5g3szn-RuKyabjUxFf6XI4",
    authDomain: "amm-votes.firebaseapp.com",
    databaseURL: "https://amm-votes.firebaseio.com",
    projectId: "amm-votes",
    storageBucket: "amm-votes.appspot.com",
    messagingSenderId: "701968751287",
    appId: "1:701968751287:web:b2e31aab033e28f97a8b32"
};

// Initialize Firebase
initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);

render(
    <MuiThemeProvider theme={theme}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </MuiThemeProvider>
, document.getElementById('react-root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
