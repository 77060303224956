import { Component } from "react";
import { Footer } from "../components/Footer";
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
//import * as queryString from 'querystring';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { RouteComponentProps } from "react-router-dom";

export interface ResetPasswordProps extends RouteComponentProps<any>
{
}

export interface ResetPasswordState 
{
    resetCode: string;
    password: string;
    errorMessage: string;
    isSubmitting: boolean;
    isComplete: boolean;
    invalidCode: boolean;
}

export class ResetPassword extends Component<ResetPasswordProps, ResetPasswordState>
{
    constructor(props: ResetPasswordProps) 
    {
        super(props);

        const params = new URLSearchParams(window.location.search);
        const param = params.get('oobCode');

        let code = '';

        if(typeof(param) === 'string')
            code = param;

        this.state = {
            resetCode: code || '',
            password: '',
            errorMessage: '',
            isSubmitting: false,
            isComplete: false,
            invalidCode: false
        };
    }

    public componentDidMount() 
    {
        const auth = getAuth();
        verifyPasswordResetCode(auth, this.state.resetCode).then((response) => 
        {
            // Code verified, no action needed
        }).catch((error) => 
        {
            // TODO: show error message about invalid code
            this.setState({ errorMessage: 'Password reset code is invalid. Please try resetting your password again from the login page', invalidCode: true });
        });
    }

    public onResetPassword(e)
    {
        if(this.state.isSubmitting === false)
        {
            this.setState({ isSubmitting: true, errorMessage: null });
            
            const auth = getAuth();
            confirmPasswordReset(auth, this.state.resetCode, this.state.password).then((res) => 
            {
                this.setState({ isSubmitting: false, isComplete: true });
            }).catch((error) => 
            {
                let message = 'Password reset failed. ' + error.message;
                let invalidCode = true;

                if(error.code === 'auth/expired-action-code'
                    || error.code === 'auth/invalid-action-code')
                {
                    message = 'Reset password failed. The password reset request has expired, please try resetting your password again from the login page';
                }
                else if(error.code === 'auth/weak-password')
                {
                    message = 'Password must be at least 6 characters long';
                    invalidCode = false;
                }

                this.setState({ errorMessage: message, invalidCode: invalidCode, isSubmitting: false });
            });
        }

        e.preventDefault();
    }

    public componentWillUnmount() 
    {

    }

    public render() 
    {
        let formContent = null;

        if(this.state.errorMessage)
        {
            formContent = (
                <p className="error-message">{this.state.errorMessage}</p>
            );
        }
        
        if(this.state.isComplete === true)
        {
            formContent = (
                <div className="form-reset-success">
                    <p>Password reset. You can now log in with your new password.</p>
                    <p><a href="/admin/login">Return to Login Page</a></p>
                </div>
            );
        }
        else if(this.state.invalidCode === false)
        {
            let error = null;
            
            if(this.state.errorMessage)
            {
                error = <p className="error-message">{this.state.errorMessage}</p>;
            }

            formContent = (
                <form onSubmit={this.onResetPassword.bind(this)}>
                    <p>Please enter your new password below. Your new password must be at least 6 characters long.</p>

                    <TextField
                        disabled={this.state.isSubmitting} 
                        autoFocus
                        fullWidth
                        label="New Password"
                        name="password"
                        placeholder="Enter your new password"
                        type="password"
                        margin="dense"
                        onChange={this.handleEmailChange.bind(this)}
                    />

                    {error}

                    <div className="login-button-container">

                        <Button
                            disabled={this.state.isSubmitting}
                            variant="contained" 
                            color="primary"
                            type="submit">
                            Reset Password
                        </Button>

                        <a className="forgot" href="/admin/login">Return to Login Page</a>

                    </div>
                    
                </form>
            );
        }

        return (
            <div className="page-login">

                <div className="card-login">
                    <div className="logo-container">
                        <img alt="AMM Logo" src="/images/amm.png" />
                    </div>

                    <h1>AMM Administrator<br /> and SEO Password Reset</h1>

                    {formContent}

                </div>

                <Footer />
            </div>
        );
    }

    public handleEmailChange(event)
    {
        this.setState({ password: event.target.value });
    }
}
