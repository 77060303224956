import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { MenuItem, TextField } from "@material-ui/core";
import { AMMDataService } from "../api/AMMDataService";
import { DialogSpinner } from "../components/DialogSpinner";
import { Municipality } from "../dto/Municipality";

enum DialogMode 
{
    New,
    Edit
};

export interface WardDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onSaved?:() => void;
    
    municipality?: Municipality;
    wardName?: string;
    wardNameFr?: string;
    wardOrder: number;
}

export interface WardDialogState 
{
    name: string;
    frenchName: string;
    order: string;
    mode: DialogMode;
    isLoading: boolean;
}

export class WardDialog extends React.Component<WardDialogProps, WardDialogState>
{
    constructor(props: WardDialogProps)
    {
        super(props);

        if(props.wardName)
        {
            this.state = {
                mode: DialogMode.Edit,
                name: props.wardName,
                frenchName: props.wardNameFr,
                order: props.wardOrder ? props.wardOrder.toString() : '0',
                isLoading: false
            };
        }
        else
        {
            this.state = {
                mode: DialogMode.New,
                name: '',
                frenchName: '',
                order: '0',
                isLoading: false
            };
        }
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public componentDidUpdate(prevProps: WardDialogProps, prevState: WardDialogState)
    {
        if(this.props.wardName && (!prevProps.wardName || prevProps.wardName !== this.props.wardName))
        {
            this.setState({
                name: this.props.wardName,
                frenchName: this.props.wardNameFr,
                order: this.props.wardOrder ? this.props.wardOrder.toString() : '0',
                mode: DialogMode.Edit
            });
        }
        else if(!this.props.wardName && prevProps.wardName)
        {
            this.setState({
                name: '',
                frenchName: '',
                order: '0',
                mode: DialogMode.New
            });
        }
    }

    public handleOnSave(e)
    {
        if(this.state.name)
        {
            if(this.state.mode === DialogMode.New)
            {
                this.setState({ isLoading: true });

                AMMDataService.current().getMunicipality(this.props.municipality.id).then((muni) => 
                {
                    const wards = muni.wards;
                    wards.push({ 
                        name: this.state.name, 
                        frName: this.state.frenchName,
                        order: this.state.order && this.state.order !== '0' ? parseInt(this.state.order) : null
                    });

                    AMMDataService.current().updateMunicipality(muni.id, { wards: wards }).then(() => 
                    {
                        this.setState({ isLoading: false });

                        if(this.props.onSaved)
                            this.props.onSaved();
                        else
                            this.props.onClose();
                    });
                });
            }
            else if(this.state.mode === DialogMode.Edit)
            {
                this.setState({ isLoading: true });

                AMMDataService.current().getMunicipality(this.props.municipality.id).then((muni) => 
                {
                    const wards = muni.wards;

                    // TODO: check for duplicate ward name

                    //const index = wards.indexOf(this.props.ward);
                    //wards[index] = this.state.name;
                    const foundWards = wards.filter(w => w.name === this.props.wardName);
                    const index = foundWards.length > 0 ? wards.indexOf(foundWards[0]) : -1;

                    if(index >= 0)
                    {
                        wards[index] = { 
                            name: this.state.name, 
                            frName: this.state.frenchName,
                            order: this.state.order && this.state.order !== '0' ? parseInt(this.state.order) : null
                        };
                    }

                    AMMDataService.current().updateMunicipality(muni.id, { wards }).then(() => 
                    {
                        AMMDataService.current().getCandidatesForMunicipality(muni.id).then(candidates => 
                        {
                            const candidatePromise = candidates.filter((c) => { return c.ward === this.props.wardName; }).map((c) => 
                            {
                                return AMMDataService.current().updateCandidate(c.id, { ward: this.state.name });
                            });

                            Promise.all(candidatePromise).then(() => 
                            {
                                this.setState({ isLoading: false });

                                if(this.props.onSaved)
                                    this.props.onSaved();
                                else
                                    this.props.onClose();
                            });
                        });
                    });
                });
            }
        }

        e.preventDefault();
    }

    public render() 
    {
        let title = '';
        let button = '';

        if(this.state.mode === DialogMode.New)
        {
            title = 'New Ward / LUD';
            button = 'Create';
        }
        else
        {
            title = 'Edit Ward / LUD';
            button = 'Save';
        }

        return (
            <Dialog
            open={this.props.isOpen}
            onClose={this.handleOnClose.bind(this)}
            scroll="paper"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
                <DialogSpinner showSpinner={this.state.isLoading}>
                    <form onSubmit={this.handleOnSave.bind(this)} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                            <TextField
                                required
                                autoFocus
                                fullWidth
                                label="Name"
                                placeholder="Enter a ward / LUD name"
                                type="text"
                                autoComplete="off"
                                value={this.state.name}
                                onChange={e => this.setState({ name: e.target.value })}
                                disabled={this.state.isLoading}
                                margin="normal" />

                            <TextField
                                fullWidth
                                label="Name (French)"
                                placeholder="Enter the French translation of the Ward / LUD name (if applicable)"
                                helperText="If no French translation is provided, the English name will be displayed."
                                type="text"
                                autoComplete="off"
                                value={this.state.frenchName}
                                onChange={e => this.setState({ frenchName: e.target.value })}
                                disabled={this.state.isLoading}
                                margin="normal" />

                            <TextField
                                fullWidth
                                select
                                label="Sort Order"
                                name="order"
                                value={this.state.order}
                                onChange={(e) => this.setState({ order: e.target.value })}
                                placeholder="Sort Order"
                                disabled={this.state.isLoading}
                                margin="normal">
                                <MenuItem key={0} value={'0'}></MenuItem>
                                <MenuItem key={1} value={'1'}>1</MenuItem>
                                <MenuItem key={2} value={'2'}>2</MenuItem>
                                <MenuItem key={3} value={'3'}>3</MenuItem>
                                <MenuItem key={4} value={'4'}>4</MenuItem>
                                <MenuItem key={5} value={'5'}>5</MenuItem>
                                <MenuItem key={6} value={'6'}>6</MenuItem>
                                <MenuItem key={7} value={'7'}>7</MenuItem>
                                <MenuItem key={8} value={'8'}>8</MenuItem>
                                <MenuItem key={9} value={'9'}>9</MenuItem>
                                <MenuItem key={10} value={'10'}>10</MenuItem>
                                <MenuItem key={11} value={'11'}>11</MenuItem>
                                <MenuItem key={12} value={'12'}>12</MenuItem>
                                <MenuItem key={13} value={'13'}>13</MenuItem>
                                <MenuItem key={14} value={'14'}>14</MenuItem>
                                <MenuItem key={15} value={'15'}>15</MenuItem>
                                <MenuItem key={16} value={'16'}>16</MenuItem>
                                <MenuItem key={17} value={'17'}>17</MenuItem>
                                <MenuItem key={18} value={'18'}>18</MenuItem>
                                <MenuItem key={19} value={'19'}>19</MenuItem>
                                <MenuItem key={20} value={'20'}>20</MenuItem>
                            </TextField>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleOnClose.bind(this)} color="default" disabled={this.state.isLoading}>Cancel</Button>
                        <Button type="submit" color="primary" variant="contained" disabled={this.state.isLoading}>{button}</Button>
                    </DialogActions>
                    </form>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }
}
