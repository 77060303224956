import Ward from "../dto/Ward";
import i18n from "../utils/i18n";

export default class Utils
{
    public static sortByProperty(propertyName: string, ascending: boolean = true)
    {
        return (a, b) => {
            if(a[propertyName] < b[propertyName]) { return ascending ? -1: 1; }
            else if(a[propertyName] > b[propertyName]) { return ascending ? 1 : -1; }
            else { return 0; }
        };
    }

    public static sortByProperties<T>(config: { property: string, direction: 'asc' | 'desc' }[])
    {
        if(config.length > 0)
        {
            const curr = config[0];

            return (a: T, b: T) => 
            {
                const aVal = a[curr.property] ?? null;
                const bVal = b[curr.property] ?? null;

                if(aVal && !bVal)
                    return curr.direction === 'asc' ? -1 : 1;
                else if(!aVal && bVal)
                    return curr.direction === 'asc' ? 1 : -1;
                else if(aVal < bVal)
                    return curr.direction === 'asc' ? -1 : 1;
                else if(aVal > bVal)
                    return curr.direction === 'asc' ? 1 : -1;
                else
                    if(config.length - 1 > 0)
                        return this.sortByProperties(config.slice(1))(a, b);
                    else
                        return 0;
            }
        }
        else
        {
            throw new Error('sortByProperties config parameter must contain at least one configuration');
        }
    }

    public static sortWards(a: Ward, b: Ward)
    {
        const isNum = (val) => typeof val === 'number';
        const nameProp = i18n.language.startsWith('en') ? 'name' : 'frName';

        if(!isNum(a.order) && !isNum(b.order))
        {
            if(a[nameProp] < b[nameProp]) { return -1; }
            else if(a[nameProp] > b[nameProp]) { return 1; }
            else { return 0; }
        }
        else if(!isNum(a.order) && isNum(b.order))
        {
            return 1;
        }
        else if(isNum(a.order) && !isNum(b.order))
        {
            return -1;
        }

        if(a.order < b.order) { return -1; }
        else if(a.order > b.order) { return 1; }
        else
        {
            if(a[nameProp] < b[nameProp]) { return -1; }
            else if(a[nameProp] > b[nameProp]) { return 1; }
            else { return 0; }
        }
    }
}