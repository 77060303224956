import { Component } from "react";
import { TopNavigation } from "../components/TopNavigation";
import { AMMRestService } from "../api/AMMRestService";
import { Municipality } from "../dto/Municipality";
import { MunicipalityPanel } from "../components/MunicipalityPanel";
import { MunicipalityList } from "../components/MunicipalityList";
import { MapBox } from "../map/MapBox";
import { DisclaimerDialog } from "../dialogs/DisclaimerDialog";

import Button from "@material-ui/core/Button";
import './Map.scss';
import { Spinner } from "../components/Spinner";

export interface MapProps 
{
    maxRecents?: number;
}

export interface MapState 
{
    municipalities: Municipality[];
    recents: Municipality[];

    mapMunicipality: Municipality;
    popupOpen: boolean;

    panelMunicipality: Municipality;
    panelOpen: boolean;
    listOpen: boolean;

    disclaimerOpen: boolean;

    intervalId: any;
    loading: boolean;
    hideLoader: boolean;
}

export class Map extends Component<MapProps, MapState>
{
    private static RECENTS_KEY = 'amm-recents';

    public static defaultProps: Partial<MapProps> = {
        maxRecents: 3
    };

    constructor(props: MapProps) 
    {
        super(props);

        this.state = {
            municipalities: [],
            recents: [],
            mapMunicipality: null,
            popupOpen: false,
            panelMunicipality: null,
            panelOpen: false,
            listOpen: false,
            disclaimerOpen: false,
            intervalId: -1,
            loading: true,
            hideLoader: false
        };
    }

    public componentDidMount() 
    {
        AMMRestService.current().getMunicipalities().then((municipalities) => 
        {
            const savedRecents = [];
            if(window.localStorage)
            {
                try
                {
                    const stored = JSON.parse(window.localStorage.getItem(Map.RECENTS_KEY));
                    if(stored && stored.length > 0)
                    {
                        stored.forEach((mId) => 
                        {
                            const found = municipalities.filter((m) => m.id === mId);
                            if(found && found.length > 0)
                                savedRecents.push(found[0]);
                        });
                    }
                }
                catch(ex) { /* no recents */ }
            }

            this.setState({ 
                municipalities: municipalities, 
                mapMunicipality: null,
                panelMunicipality: null,
                panelOpen: false,
                recents: savedRecents,
                loading: false
            });
            
            setTimeout(() => {
                this.setState({ hideLoader: true });
            }, 300);
        });

        const id = setInterval(() => 
        {
            try
            {
                AMMRestService.current().getMunicipalities().then((munis) => 
                {
                    const savedRecents = [];
                    if(window.localStorage)
                    {
                        try
                        {
                            const stored = JSON.parse(window.localStorage.getItem(Map.RECENTS_KEY));
                            if(stored && stored.length > 0)
                            {
                                stored.forEach((mId) => 
                                {
                                    let found = munis.filter((m) => m.id === mId);
                                    if(found && found.length > 0)
                                        savedRecents.push(found[0]);
                                });
                            }
                        }
                        catch(ex) { /* no recents */ }
                    }
    
                    this.setState({ municipalities: [] });
                    this.setState({
                        municipalities: munis,
                        recents: savedRecents
                    });
                });
            } catch(e) { }
        }, 60000);
        this.setState({ intervalId: id });
    }

    public componentWillUnmount() 
    {
        clearInterval(this.state.intervalId);
    }

    public render() 
    {
        return (
            <div className="page-map">
                <link rel="stylesheet" href="/styles/map.css" />
                <DisclaimerDialog 
                    onClose={() => { this.setState({ disclaimerOpen: false }); }}
                    isOpen={this.state.disclaimerOpen} />

                <div className="map-container">
                    <div className={"map-loading" + (this.state.loading ? ' loading' : '') + (this.state.hideLoader ? ' hide' : '')}>
                        <Spinner />
                        <div>Loading Map</div>
                    </div>

                    <MapBox
                        onViewDetailsClicked={this.onViewDetailsClicked.bind(this)}
                        onMunicipalityClicked={this.onMunicipalityClicked.bind(this)}
                        selectedMunicipality={this.state.mapMunicipality}
                        popupOpen={this.state.popupOpen}
                        municipalities={this.state.municipalities}
                        offset={this.state.panelOpen ? { x: 590/2, y: 0 } : null} />
                </div>

                <div className="nav-container">
                    <TopNavigation />
                </div>

                <div className="site-container">

                    <MunicipalityList 
                        onRequestClose={() => { this.setState({ listOpen: false }); }}
                        isOpen={this.state.listOpen}
                        selectedMunicipality={this.state.panelMunicipality}
                        recentMunicipalities={this.state.recents}
                        onSelectMunicipality={this.onSelectMunicipality.bind(this)}
                        onViewMunicipality={this.onViewMunicipality.bind(this)}
                        municipalities={this.state.municipalities} />
                    
                    <MunicipalityPanel
                        isOpen={this.state.panelOpen}
                        onDismiss={e => this.setState({ panelOpen: false, panelMunicipality: null })}
                        municipality={this.state.panelMunicipality} />

                </div>

                <div className="mobile-controls">
                    <Button 
                        style={{ height: '50px' }}
                        fullWidth
                        variant="contained" 
                        onClick={(e) => { this.setState({ listOpen: true }); }}
                        color="primary">Find a municipality</Button>
                </div>
            </div>
        );
    }

    // User selects municipality in dropdown
    private onSelectMunicipality(m: Municipality)
    {
        this.setState({ panelOpen: false, panelMunicipality: m, mapMunicipality: m, popupOpen: true });
    }

    // User selects municipality in recent locations
    private onViewMunicipality(m: Municipality)
    {
        this.setState({ panelOpen: true, panelMunicipality: m, mapMunicipality: m, popupOpen: true });
    }

    // User clicks on municipality in map
    private onMunicipalityClicked(m: Municipality)
    {
        this.setState({ panelOpen: false, panelMunicipality: m, mapMunicipality: m, popupOpen: true });
    }

    // User clicks "View Details" button
    private onViewDetailsClicked(m: Municipality)
    {
        const recents = this.state.recents;
        const found = recents.filter(function(r) { return r.id === m.id; });
        const index = recents.indexOf(found.length > 0 ? found[0] : null);

        if(index === -1)
        {
            recents.unshift(m);
        
            while(recents.length > this.props.maxRecents)
            {
                recents.pop();
            }
        }
        else
        {
            let muni = recents.splice(index, 1);
            if(muni && muni.length > 0)
                recents.unshift(muni[0]);
        }

        this.setState({ panelOpen: true, panelMunicipality: m, mapMunicipality: m, popupOpen: true, recents: recents });

        if(window.localStorage)
        {
            const recentIds = recents.map(function(r) { return r.id });
            window.localStorage.setItem(Map.RECENTS_KEY, JSON.stringify(recentIds));
        }
    }
}
