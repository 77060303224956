import { Component } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

export interface DialogSpinnerProps
{
    className?: string;
    showSpinner?: boolean;
    message?: string;
}

export interface DialogSpinnerState
{
    show: boolean;
}

export class DialogSpinner extends Component<DialogSpinnerProps, DialogSpinnerState>
{
    public static defaultProps: Partial<DialogSpinnerProps> = {
        showSpinner: false
    };

    constructor(props: DialogSpinnerProps)
    {
        super(props);

        this.state = {
            show: true
        }
    }

    public componentDidMount()
    {
        
    }

    public componentDidUpdate(prevProps: DialogSpinnerProps, prevState: DialogSpinnerState)
    {
        if(this.props.showSpinner && prevProps.showSpinner === false)
        {
            this.setState({ show : true });
        }
    }

    public render()
    {
        let message = null;

        if(this.props.message)
            message = <div className="dialog-message">{this.props.message}</div>

        return (
            <div className="component-dialog-spinner">
                { this.props.showSpinner &&
                    <div className={'dialog-spinner' + (this.state.show ? ' show' : '') }>
                        <CircularProgress color="secondary" />
                        {message}
                    </div>
                }
                {this.props.children}
            </div>
        );
    }
}