import { Component } from "react";
import * as mapboxgl from 'mapbox-gl';
import { Municipality } from "../dto/Municipality";
import Button from '@material-ui/core/Button';
import i18n from "../utils/i18n";

export interface MunicipalityPopupProps
{
    map: any;
    isOpen: boolean;
    coordinates: [number, number];
    municipality: Municipality;

    onViewDetailsClicked?: (m: Municipality) => void;
}

export interface MunicipalityPopupState
{

}

export class MunicipalityPopup extends Component<MunicipalityPopupProps, MunicipalityPopupState>
{
    private popup: any;
    private popupElement: any;

    constructor(props: MunicipalityPopupProps)
    {
        super(props);

        this.state = {
            
        };
    }

    public componentDidMount()
    {
        if(this.props.isOpen 
            && this.props.map
            && this.props.coordinates
            && this.props.municipality)
        {
            this.popup = this.createPopup(
                this.props.map, 
                this.props.coordinates, 
                this.props.municipality);
        }
    }

    public componentDidUpdate(prevProps: MunicipalityPopupProps, prevState: MunicipalityPopupState)
    {
        if((this.props.coordinates !== prevProps.coordinates && this.props.isOpen)
            || (this.props.isOpen !== prevProps.isOpen))
        {
            this.destroyPopup();
            this.popup = this.createPopup(
                this.props.map,
                this.props.coordinates,
                this.props.municipality
            );
        }
    }

    private createPopup(map: any, coordinates: [number, number], municipality: Municipality)
    {
        let popup = new mapboxgl.Popup({
            closeButton: true,
            closeOnClick: true,
            anchor: 'bottom',
            className: 'component-municipality-popup'
        });

        popup.setDOMContent(this.popupElement);
        popup.setLngLat(coordinates);
        popup.addTo(map);

        return popup;
    }

    private destroyPopup()
    {
        if(this.popup)
        {
            this.popup.remove();
            this.popup = null;
        }
    }

    public componentWillUnmount()
    {
        this.destroyPopup();
    }

    public render()
    {
        let name = '';
        let type = '';
        let status = '';
        let resultsDeclared = false;

        if(this.props.municipality)
        {
            const muni = this.props.municipality;
            name = muni.getName();
            type = muni.getTypeString();
            resultsDeclared = muni.resultsDeclared;
            status = resultsDeclared ? i18n.t('MunicipalityPopup-Declared') : i18n.t('MunicipalityPopup-InProgress');
        }

        return (
            <div className="hidden">
                <div ref={(el) => { this.popupElement = el; }}>
                    <div className="popup-info">
                        <h2>{name}</h2>
                        <div className="popup-subtitle">{type}</div>
                        <div className={"count-status" + (resultsDeclared ? ' declared' : '')}>{status}</div>
                    </div>
                    <div className="popup-results">

                    </div>
                    <div className="popup-actions">
                        <Button 
                            fullWidth
                            onClick={this.onButtonClick.bind(this)}
                            variant="outlined">
                            {i18n.t('MunicipalityPopup-View')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    private onButtonClick(e)
    {
        if(this.props.onViewDetailsClicked)
            this.props.onViewDetailsClicked(this.props.municipality);
    }
}