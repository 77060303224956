import { Component } from "react";
import { Route, Switch } from 'react-router-dom';

import { Map } from './pages/Map';
import { About } from './pages/About';
import { Contact } from './pages/Contact';

import { NotFound } from "./pages/NotFound";
import { Login } from "./pages-admin/Login";
import { Logout } from "./pages-admin/Logout";
import { Dashboard } from "./pages-admin/Dashboard";
import { ForgotPassword } from "./pages-admin/ForgotPassword";
import { ResetPassword } from "./pages-admin/ResetPassword";
import ResultsDashboard from "./pages/ResultsDashboard";

export interface AppProps 
{

}

export interface AppState 
{
    isOpen: boolean;
}

export class App extends Component<AppProps, AppState>
{
    constructor(props: AppProps) 
    {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public render() 
    {
        return (
            <div className="app">
                <Switch>
                    <Route exact path="/" component={Map} />
                    <Route path="/about" component={About} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/dashboard" component={ResultsDashboard} />

                    <Route path="/admin/login" component={Login} />
                    <Route path="/admin/logout" component={Logout} />
                    <Route path="/admin/forgot" component={ForgotPassword} />
                    <Route path="/admin/reset" component={ResetPassword} />
                    <Route path="/admin" component={Dashboard} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </div>
        );
    }
}
