import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Account } from "../dto/Account";
import { AMMDataService } from "../api/AMMDataService";
import { DialogSpinner } from "../components/DialogSpinner";

export interface AccountDeleteDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onDeleted?:() => void;

    account: Account;
}

export interface AccountDeleteDialogState 
{
    isLoading: boolean;
}

export class AccountDeleteDialog extends React.Component<AccountDeleteDialogProps, AccountDeleteDialogState>
{
    constructor(props: AccountDeleteDialogProps)
    {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public handleOnDelete()
    {
        this.setState({ isLoading: true });

        AMMDataService.current().deleteAccount(this.props.account.uid).then(() => 
        {
            this.setState({ isLoading: false });

            if(this.props.onDeleted)
                this.props.onDeleted();
            else
                this.props.onClose();
        });
    }

    public render() 
    {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogSpinner showSpinner={this.state.isLoading} message="Deleting account. Please wait...">
                    <DialogTitle id="alert-dialog-title">{`Delete ${this.props.account ? this.props.account.name : ''}?`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Are you sure you want to delete this account? This action cannot be undone.`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleOnClose.bind(this)} color="default" autoFocus>Cancel</Button>
                        <Button onClick={this.handleOnDelete.bind(this)} color="primary" variant="contained" style={{ backgroundColor: '#f44336' }}>Delete</Button>
                    </DialogActions>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }
}
