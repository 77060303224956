import i18n from "../utils/i18n";

export class Candidate
{
    public id: string;
    public municipality: string;

    public firstName: string;
    public lastName: string;
    public gender: string;
    public position: string;
    public ward: string;
    public previouslyServed: boolean;
    public previouslyElected: boolean;
    public elected: number;
    public votes: number;
    public order: number;

    // public static fromDoc(doc: firebase.firestore.QueryDocumentSnapshot)
    // {
    //     let data = doc.data();

    //     let a = new Candidate();
    //     a.id = doc.id;
    //     a.municipality = data.municipality;
    //     a.firstName = data.firstName;
    //     a.lastName = data.lastName;
    //     a.gender = data.gender;
    //     a.position = data.position;
    //     a.ward = data.ward;
    //     a.previouslyServed = data.previouslyServed;
    //     a.previouslyElected = data.previouslyElected;
    //     a.elected = data.elected;
    //     a.votes = data.votes;
    //     a.order = data.order || null;

    //     return a;
    // }

    // public static fromJson(json)
    // {
    //     let id = '';
    //     let components =  json.name.split('/');
    //     if(components && components.length > 0)
    //         id = components[components.length - 1];

    //     let fields = json.fields;

    //     let m = new Candidate();
    //     m.id = id;
    //     m.municipality = this.getValue(fields, 'municipality', 'stringValue');
    //     m.firstName = this.getValue(fields, 'firstName', 'stringValue');
    //     m.lastName = this.getValue(fields, 'lastName', 'stringValue');
    //     m.gender = this.getValue(fields, 'gender', 'stringValue');
    //     m.position = this.getValue(fields, 'position', 'stringValue');
    //     m.ward = this.getValue(fields, 'ward', 'stringValue');
    //     m.previouslyServed = this.getValue(fields, 'previouslyServed', 'booleanValue');
    //     m.previouslyElected = this.getValue(fields, 'previouslyElected', 'booleanValue');
    //     m.elected = this.getValue(fields, 'elected', 'integerValue');
    //     m.votes = this.getValue(fields, 'votes', 'integerValue');
    //     m.order = this.getValue(fields, 'order', 'integerValue');

    //     return m;
    // }

    public static fullName(candidate: Candidate)
    {
        return candidate.firstName + ' ' + (candidate.lastName ? candidate.lastName.toUpperCase() : candidate.lastName);
    }

    private static getValue(fields: any, fieldName: string, type: string)
    {
        if(type === 'integerValue')
        {
            return fields[fieldName] ? parseInt(fields[fieldName][type]) : null;
        }
        else
        {
            return fields[fieldName] ? fields[fieldName][type] : null;
        }
    }

    public static getPositionString(position: string)
    {
        return i18n.t(`Position-${position.replace(/\s+/gi, '')}`, { defaultValue: position });
    }

    public static getElectedStatusString(status: ElectedStatus)
    {
        let retval = '';

        switch(status)
        {
            case 0: retval = i18n.t('Candidate-NotElected'); break;
            case 1: retval = i18n.t('Candidate-Elected'); break;
            case 2: retval = i18n.t('Candidate-Acclaimed'); break;
        }

        return retval;
    }

    public static sort(a: Candidate, b: Candidate)
    {
        if(a.order === null && b.order === null)
        {
            if(a.lastName < b.lastName) { return -1; }
            else if(a.lastName > b.lastName) { return 1; }
            else { return 0; }
        }
        else if(a.order === null && b.order !== null)
        {
            return 1;
        }
        else if(a.order !== null && b.order === null)
        {
            return -1;
        }

        if(a.order < b.order) { return -1; }
        else if(a.order > b.order) { return 1; }
        else
        {
            if(a.lastName < b.lastName) { return -1; }
            else if(a.lastName > b.lastName) { return 1; }
            else { return 0; }
        }
    }
}

export enum ElectedStatus 
{
    NotElected = 0,
    Elected = 1,
    Acclaimed = 2
};