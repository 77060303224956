import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { TextField } from "@material-ui/core";
import { AMMDataService } from "../api/AMMDataService";
import { Account } from "../dto/Account";
import { AccountPasswordResetDialog } from "./AccountPasswordResetDialog";
import { AccountChangeEmailDialog } from "./AccountChangeEmailDialog";
import { AccountChangeNameDialog } from "./AccountChangeNameDialog";
import { AccountDeleteDialog } from "./AccountDeleteDialog";

export interface AccountDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onSaved?:() => void;
    onDeleted?: () => void;

    account?: Account;
}

export interface AccountDialogState 
{
    email: string;
    name: string;
    role: string;
    password: string;
    isSubmitting: boolean;

    passwordResetOpen: boolean;
    changeEmailOpen: boolean;
    changeNameOpen: boolean;
    deleteAccountOpen: boolean;
}

export class AccountEditDialog extends React.Component<AccountDialogProps, AccountDialogState>
{
    constructor(props: AccountDialogProps)
    {
        super(props);

        if(props.account)
        {
            let account = props.account;

            this.state = {
                email: account.email,
                name: account.name,
                role: account.role,
                password: '********',
                isSubmitting: false,
                passwordResetOpen: false,
                changeEmailOpen: false,
                changeNameOpen: false,
                deleteAccountOpen: false
            };
        }
        else
        {
            this.state = {
                email: '',
                name: '',
                role: '',
                password: '********',
                isSubmitting: false,
                passwordResetOpen: false,
                changeEmailOpen: false,
                changeNameOpen: false,
                deleteAccountOpen: false
            };
        }
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public componentDidUpdate(prevProps: AccountDialogProps, prevState: AccountDialogState)
    {
        if(this.props.account && (!prevProps.account || prevProps.account !== this.props.account))
        {
            let account = this.props.account;
            this.setState({
                email: account.email,
                name: account.name,
                role: account.role,
                password: '********'
            });
        }
    }

    public render() 
    {
        let alignRight: React.CSSProperties = {
            textAlign: 'right'
        };

        return (
            <div>
                <Dialog
                open={this.props.isOpen 
                    && this.state.passwordResetOpen === false 
                    && this.state.changeEmailOpen === false
                    && this.state.changeNameOpen === false
                    && this.state.deleteAccountOpen === false}
                onClose={this.handleOnClose.bind(this)}
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">Edit Account</DialogTitle>
                    <DialogContent>
                            <div style={alignRight}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    placeholder="Enter an email address"
                                    type="email"
                                    autoComplete="off"
                                    color="#292D30"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    disabled />
                                <Button color="primary" onClick={() => { this.setState({ changeEmailOpen: true }); }}>Change Email</Button>
                            </div>

                            <div style={alignRight}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    fullWidth
                                    autoComplete="off"
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    placeholder="Enter a display name"
                                    color="#292D30"
                                    disabled />
                                <Button color="primary" onClick={() => { this.setState({ changeNameOpen: true }); }}>Edit Name</Button>
                            </div>

                            <div style={alignRight}>
                                <TextField
                                    label="Password"
                                    name="password"
                                    fullWidth
                                    color="#292D30"
                                    value={this.state.password}
                                    onChange={(e) => this.setState({ password: e.target.value })}
                                    disabled />
                                <Button color="primary" onClick={() => { this.setState({ passwordResetOpen: true }); }}>Reset Password</Button>
                            </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ deleteAccountOpen: true }); }} disabled={this.state.isSubmitting}>Delete Account</Button>
                        <Button onClick={this.handleOnClose.bind(this)} disabled={this.state.isSubmitting}>Close</Button>
                    </DialogActions>

                </Dialog>

                <AccountPasswordResetDialog
                    isOpen={this.state.passwordResetOpen}
                    onClose={() => { this.setState({ passwordResetOpen: false }); }}
                    onReset={() => { this.setState({ passwordResetOpen: false }); this.handleOnSave(); }}
                    account={this.props.account} />

                <AccountChangeEmailDialog
                    isOpen={this.state.changeEmailOpen}
                    onClose={() => { this.setState({ changeEmailOpen: false }); }}
                    onReset={() => { this.setState({ changeEmailOpen: false }); this.handleOnSave(); }}
                    account={this.props.account} />

                <AccountChangeNameDialog
                    isOpen={this.state.changeNameOpen}
                    onClose={() => { this.setState({ changeNameOpen: false }); }}
                    onSave={() => { this.setState({ changeNameOpen: false }); this.handleOnSave(); }}
                    account={this.props.account} />

                <AccountDeleteDialog
                    isOpen={this.state.deleteAccountOpen}
                    onClose={() => { this.setState({ deleteAccountOpen: false }); }}
                    onDeleted={() => { this.setState({ deleteAccountOpen: false }); this.handleOnDelete(); }}
                    account={this.props.account} />
                
            </div>
        );
    }

    public handleOnSave()
    {
        if(this.props.onSaved)
            this.props.onSaved();
        else
            this.props.onClose();
    }

    public handleOnClose()
    {
        if(!this.state.isSubmitting)
        {
            this.props.onClose();
        }
    }

    public handleOnDelete()
    {
        if(!this.state.isSubmitting && this.props.onDeleted)
        {
            this.props.onDeleted();
        }
    }
}
