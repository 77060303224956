import { Component } from "react";
import { Account } from "../dto/Account";

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AMMDataService } from "../api/AMMDataService";
import { AccountDeleteDialog } from "../dialogs/AccountDeleteDialog";
import { AccountDialog } from "../dialogs/AccountDialog";
import { Link, RouteComponentProps } from "react-router-dom";
import { AccountEditDialog } from "../dialogs/AccountEditDialog";
import { Spinner } from "../components/Spinner";
import { Breadcrumb } from "../components/Breadcrumb";
import { BreadcrumbLink } from "../components/BreadcrumbLink";
import Utils from "../utils/Utils";

export interface SEOTabProps extends RouteComponentProps<any>
{

}

export interface SEOTabState 
{
    accounts: Account[];
    selectedAccount: Account;
    createDialogOpen: boolean;
    editDialogOpen: boolean;
    deleteDialogOpen: boolean;
    isLoading: boolean;
}

export class SEOTab extends Component<SEOTabProps, SEOTabState>
{
    constructor(props: SEOTabProps) 
    {
        super(props);

        this.state = {
            accounts: [],
            selectedAccount: null,
            createDialogOpen: false,
            editDialogOpen: false,
            deleteDialogOpen: false,
            isLoading: true
        };
    }

    public componentDidMount() 
    {
        this.refreshAccounts();
    }

    public componentWillUnmount() 
    {

    }

    public refreshAccounts()
    {
        AMMDataService.current().getSEOAccounts().then((accounts) => 
        {
            this.setState({ accounts: accounts.sort(Utils.sortByProperty('name')), isLoading: false });
        });
    }

    public render() 
    {
        let path = this.props.match.path;
        let data = this.state.accounts;
        let selected = this.state.selectedAccount;
        
        let tableContent = null;

        if(this.state.isLoading === false)
        {
            tableContent = data.map((d) => {
                return (
                    <TableRow key={d.id}>
                        <TableCell component="th" scope="row">
                            <Link to={`${path}/${d.id}`}>{d.name}</Link>
                        </TableCell>
                        <TableCell>{Account.roleString(d)}</TableCell>
                        <TableCell>{d.municipalityCount} {d.municipalityCount === 1 ? 'municipality' : 'municipalities'}</TableCell>
                        <TableCell>
                            <Button size="small" variant="outlined" onClick={(e) => { this.setState({ editDialogOpen: true, selectedAccount: d }) }}>Edit</Button>
                            <Button size="small" variant="outlined" style={{ marginLeft: '10px'}} onClick={e => this.setState({ deleteDialogOpen: true, selectedAccount: d })}>
                                Delete
                            </Button>
                        </TableCell>
                    </TableRow>
                );
            });
        }
        else
        {
            tableContent = (
                <TableRow>
                    <TableCell colSpan={4}><Spinner /></TableCell>
                </TableRow>
            );
        }

        return (
            <div className="tab-accounts">
                <Breadcrumb>
                    <BreadcrumbLink>Senior Election Officials List</BreadcrumbLink>
                </Breadcrumb>

                <h1>Senior Election Officials</h1>

                <Paper>
                    <h3>Senior Election Officials</h3>
                    <p>Below is a list of all Senior Election Officials (SEO). Click on an account below to view, edit or delete an SEO.</p>
                    <Button 
                        onClick={(e) => { this.setState({ createDialogOpen: true, selectedAccount: null }); }}
                        variant="contained" 
                        color="primary">
                        Add Account
                    </Button>

                    <Table style={{ marginTop: '10px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Assigned Municipalities</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableContent}
                        </TableBody>
                    </Table>

                    <AccountDeleteDialog
                        account={this.state.selectedAccount}
                        isOpen={this.state.deleteDialogOpen}
                        onDeleted={this.handleAccountDeleted.bind(this)}
                        onClose={this.handleDeleteClose.bind(this)} />

                    <AccountDialog 
                        account={this.state.selectedAccount}
                        isOpen={this.state.createDialogOpen}
                        onCreated={this.handleAccountCreated.bind(this)}
                        onSaved={this.handleAccountSaved.bind(this)}
                        onClose={this.handleCreateClose.bind(this)} />

                    <AccountEditDialog
                        account={this.state.selectedAccount}
                        isOpen={this.state.editDialogOpen}
                        onSaved={this.handleAccountSaved.bind(this)}
                        onDeleted={this.handleAccountDeleted.bind(this)}
                        onClose={() => { this.setState({ editDialogOpen: false }); }} />

                </Paper>
            </div>
        );
    }

    private handleAccountCreated(uid: string)
    {
        this.setState({ createDialogOpen: false });
        this.props.history.push(`/admin/accounts/${uid}`);
    }

    private handleAccountSaved()
    {
        this.refreshAccounts();
        this.setState({ createDialogOpen: false });
    }

    private handleCreateClose()
    {
        this.setState({ createDialogOpen: false });
    }

    private handleAccountDeleted()
    {
        this.refreshAccounts();
        this.setState({ deleteDialogOpen: false });
    }

    private handleDeleteClose()
    {
        this.setState({ deleteDialogOpen: false });
    }
}
