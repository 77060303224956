import { Component } from "react";
import { Municipality } from '../dto/Municipality';
import { Panel } from './Panel';

import Button from '@material-ui/core/Button';
import { PositionListItem } from './PositionListItem';
import { Candidate } from '../dto/Candidate';
import { AMMRestService } from '../api/AMMRestService';
import { Spinner } from './Spinner';
import i18n from "../utils/i18n";
import Utils from "../utils/Utils";

export interface MunicipalityPanelProps
{
    isOpen: boolean;
    municipality: Municipality;

    onDismiss: (e) => void;
}

export interface MunicipalityPanelState
{
    isLoading: boolean;
    positions: { name: string, candidates: Candidate[] }[];
}

export class MunicipalityPanel extends Component<MunicipalityPanelProps, MunicipalityPanelState>
{
    constructor(props: MunicipalityPanelProps)
    {
        super(props);

        this.state = {
            isLoading: true,
            positions: []
        };
    }

    public componentDidMount()
    {
        this.refreshCandidates();
    }

    public componentWillUnmount()
    {
    }

    public componentDidUpdate(prevProps: MunicipalityPanelProps, prevState: MunicipalityPanelState)
    {
        if(this.props.isOpen && !prevProps.isOpen)
        {
            this.refreshCandidates();
        }
        else if(this.props.isOpen && (this.props.municipality !== prevProps.municipality))
        {
            this.refreshCandidates();
        }
    }

    private refreshCandidates()
    {
        if(this.props.municipality)
        {
            this.setState({ isLoading: true });

            AMMRestService.current().getCandidatesForMunicipality(this.props.municipality.id).then((candidates) => 
            {
                candidates.sort(Candidate.sort);

                const headCandidates = candidates.filter((c) => 
                { 
                    const pos = c.position.toLowerCase();
                    return pos === 'mayor' || pos === 'reeve' || pos === 'head of council';
                });

                const councilCandidates = candidates.filter((c) => 
                {
                    return headCandidates.indexOf(c) === -1;
                });

                const wards: {[key: string]: Candidate[]} = {};
                councilCandidates.forEach((c) => 
                {
                    let a = wards[c.ward] || [];
                    a.push(c);
                    wards[c.ward] = a;
                });

                let positions: Array<{ name: string, candidates: Candidate[], order: number }> = [];
                for(const wardName in wards)
                {
                    if(wards.hasOwnProperty(wardName))
                    {
                        let name = wardName;
                        let order = null;

                        const ward = Municipality.getWard(this.props.municipality, name);
                        order = ward?.order ?? null;

                        if(i18n.language.indexOf('fr') === 0)
                        {
                            if(ward)
                                name = ward.frName ? ward.frName : wardName;
                        }

                        if(wardName === 'At Large')
                        {
                            name = i18n.t('Position-AtLarge');
                            order = 0;
                        }

                        positions.push({
                            name,
                            candidates: wards[wardName],
                            order
                        });
                    }
                }

                positions.sort(Utils.sortByProperties([{ property: 'order', direction: 'asc' }, { property: 'name', direction: 'asc' }]));

                if(headCandidates.length > 0)
                {
                    positions = [{ name: Candidate.getPositionString(headCandidates[0].position), candidates: headCandidates, order: -1 }, ...positions];
                }

                this.setState({ positions: positions, isLoading: false });
            });
        }
    }

    public render()
    {
        let name = '';
        let type = '';
        let resultsDeclared = false;
        let targetResults = '';
        let candidateItems = [];

        if(this.props.municipality)
        {
            let muni = this.props.municipality;
            name = muni.getName();
            type = muni.getTypeString();
            resultsDeclared = muni.resultsDeclared;
            //targetResults = muni.targetResults;

            if(this.state.isLoading)
            {
                candidateItems = [
                    <Spinner key="0" />
                ]
            }
            else if(this.state.isLoading === false)
            {
                if(this.state.positions && this.state.positions.length > 0)
                {
                    this.state.positions.forEach((p, index) => 
                    {
                        candidateItems.push(
                            <PositionListItem key={index} name={p.name} candidates={p.candidates} resultsDeclared={resultsDeclared} />
                        );
                    });
                }
                else
                {
                    candidateItems.push(<p key="0">{i18n.t('MunicipalityPanel-NoCandidates')}</p>);
                }
            }
        }

        return (
            <Panel className={'component-municipality-panel' + (this.props.isOpen ? ' open': '')}>
                <div className="panel-content">
                    <div className="panel-header">
                        <div className="panel-actions">
                            <Button 
                                onClick={this.onCloseClicked.bind(this)}
                                variant="outlined"
                                color="primary">
                                {i18n.t('MunicipalityPanel-Hide')}
                            </Button>
                        </div>

                        <h1>{name}</h1>
                        <div>{type}</div>
                        <div className={"count-status" + (resultsDeclared ? ' declared' : '')}>{resultsDeclared ? i18n.t('MunicipalityPanel-Declared') : i18n.t('MunicipalityPanel-InProgress')}</div>
                        <p>{targetResults}</p>
                    </div>

                    <div className="municipality-positions">
                        {candidateItems}
                    </div>

                </div>
            </Panel>
        );
    }

    private onCloseClicked(e)
    {
        if(this.props.onDismiss)
            this.props.onDismiss(e);
    }
}