import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { TextField, MenuItem } from "@material-ui/core";
import { Municipality } from "../dto/Municipality";
import { AMMDataService } from "../api/AMMDataService";
import { DialogSpinner } from "../components/DialogSpinner";

export interface MunicipalityDeleteDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onDeleted?:() => void;

    municipality: Municipality;
}

export interface MunicipalityDeleteDialogState 
{
    isLoading: boolean;
}

export class MunicipalityDeleteDialog extends React.Component<MunicipalityDeleteDialogProps, MunicipalityDeleteDialogState>
{
    constructor(props: MunicipalityDeleteDialogProps)
    {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public handleOnDelete()
    {
        this.setState({ isLoading: true });

        AMMDataService.current().deleteMunicipality(this.props.municipality.id).then(() => 
        {
            this.setState({ isLoading: false });

            if(this.props.onDeleted)
                this.props.onDeleted();
            else
                this.props.onClose();
        });
    }

    public render() 
    {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogSpinner showSpinner={this.state.isLoading}>
                    <DialogTitle id="alert-dialog-title">{`Delete ${this.props.municipality ? this.props.municipality.name : ''}?`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Are you sure you want to delete this municipality? All wards associated with this municipality will be lost. This action cannot be undone.`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleOnClose.bind(this)} color="default" autoFocus>Cancel</Button>
                        <Button onClick={this.handleOnDelete.bind(this)} color="primary" variant="contained" style={{ backgroundColor: '#f44336' }}>Delete</Button>
                    </DialogActions>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }
}
