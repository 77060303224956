import { Component } from "react";
import { TopNavigation } from "../components/TopNavigation";
import { Footer } from "../components/Footer";
import i18n from "../utils/i18n";
import { Trans } from "react-i18next";

export interface ContactProps 
{
}

export interface ContactState 
{
}

export class Contact extends Component<ContactProps, ContactState>
{
    constructor(props: ContactProps) 
    {
        super(props);

        this.state = {
        };
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {
        
    }

    public render() 
    {
        return (
            <div className="page-contact">
                {/* <link rel="stylesheet" href="/styles/map.css" /> */}
                <div className="nav-container">
                    <TopNavigation />
                </div>

                <div className="contact-page-content">
                    <div className="card">
                        <div>
                            <a href="/">{i18n.t('ContactUs-Home')}</a>
                            <span className="divider">/</span>
                            <span>{i18n.t('ContactUs-Title')}</span>
                        </div>
                        <h1>{i18n.t('ContactUs-Title')}</h1>
                        <p>Association of Manitoba Municipalities</p>
                        <p>{i18n.t('ContactUs-Address')}</p>
                        <p>{i18n.t('ContactUs-City')}</p>
                        <p>R1N 0P1</p>
                        <p>Canada</p>
                        <p>{i18n.t('ContactUs-Phone')}</p>
                        <p>{i18n.t('ContactUs-Fax')}</p>
                        <br />
                        <p>
                            <Trans i18nKey="ContactUs-MoreInformation">
                                For more information, please visit our website at <a href="http://www.amm.mb.ca">www.amm.mb.ca</a>
                            </Trans>
                        </p>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}
