import { Component } from "react";
import { TopNavigation } from "../components/TopNavigation";
import { Footer } from "../components/Footer";
import i18n from "../utils/i18n";
import { Trans } from "react-i18next";

export interface AboutProps 
{
}

export interface AboutState 
{
}

export class About extends Component<AboutProps, AboutState>
{
    constructor(props: AboutProps) 
    {
        super(props);

        this.state = {
        };
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public render() 
    {
        return (
            <div className="page-about">
                {/* <link rel="stylesheet" href="/styles/map.css" /> */}
                <div className="nav-container">
                    <TopNavigation />
                </div>

                <div className="about-page-content">
                    <div className="card">
                        <div>
                            <a href="/">{i18n.t('AboutUs-Home')}</a>
                            <span className="divider">/</span>
                            <span>{i18n.t('AboutUs-Title')}</span>
                        </div>
                        <h1>{i18n.t('AboutUs-Title')}</h1>

                        <p>Association of Manitoba Municipalities</p>
                        <p>
                            <Trans i18nKey="AboutUs-Content1">
                                The AMM was created in January 1999 as a result of a merger between the Union of Manitoba Municipalities (UMM) and the Manitoba Association of Urban Municipalities (MAUM). Representing all of Manitoba's incorporated municipalities, including the City of Winnipeg, the AMM is funded solely by its members and by its business arm, the <a href="http://www.amm.mb.ca/members/tradingcompany/">Trading Company</a>
                            </Trans>
                        </p>
                        <p>
                            <Trans i18nKey="AboutUs-Content2">
                                The AMM is divided into seven districts. Two Directors represent each of the following districts: <a href="http://www.amm.mb.ca/members/municipal-map/parkland/">Parkland</a>, <a href="http://www.amm.mb.ca/members/municipal-map/interlake/">Interlake</a>, <a href="http://www.amm.mb.ca/members/municipal-map/midwestern/">Midwestern</a>, <a href="http://www.amm.mb.ca/members/municipal-map/central/">Central</a>, <a href="http://www.amm.mb.ca/members/municipal-map/western/">Western</a>, <a href="http://www.amm.mb.ca/members/municipal-map/eastern/">Eastern</a> and <a href="http://www.amm.mb.ca/members/municipal-map/northern/">Northern</a>. The City of Winnipeg is represented by one Director. Every two years at the annual convention, members elect a president and two vice-presidents who serve as the association's executive.
                            </Trans>
                        </p>
                        <p>
                            <Trans i18nKey="AboutUs-Content3">
                                AMM executive members are elected every second year during the AMM Annual Convention. AMM Directors are elected every second year during June District Meetings.
                            </Trans>
                        </p>
                        <p>
                            <Trans i18nKey="AboutUs-Content4">
                                AMM and Manitoba representatives also serve on the <a href="http://www.fcm.ca/">Federation of Canadian Municipalities</a> (FCM) Board of Directors, and attend FCM events throughout the year.
                            </Trans>
                        </p>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}
