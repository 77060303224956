import { Component } from "react";

export interface BreadcrumbProps
{
    className?: string;
}

export interface BreadcrumbState
{
}

export class Breadcrumb extends Component<BreadcrumbProps, BreadcrumbState>
{
    constructor(props: BreadcrumbProps)
    {
        super(props);

        this.state = {
        }
    }

    public componentDidMount()
    {
        
    }

    public render()
    {
        let additionalClasses = this.props.className;

        return (
            <div className={'component-breadcrumb' + (additionalClasses ? ` ${additionalClasses}`: '')}>
                {this.props.children}
            </div>
        );
    }
}
