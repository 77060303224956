import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import { AMMDataService } from "../api/AMMDataService";
import { DialogSpinner } from "../components/DialogSpinner";
import { Candidate } from "../dto/Candidate";
import { Municipality } from "../dto/Municipality";

enum DialogMode 
{
    New,
    Edit
};

export interface CandidateDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onSaved?:() => void;

    municipality: Municipality;
    candidate?: Candidate;
}

export interface CandidateDialogState 
{
    firstName: string;
    lastName: string;
    gender: string;
    position: string;
    ward: string;

    previouslyServed: boolean;
    previouslyElected: boolean;

    order: string;

    mode: DialogMode;
    isLoading: boolean;

    positionError: boolean;
    genderError: boolean;
    previouslyElectedError: boolean;
    previouslyServedError: boolean;
}

export class CandidateDialog extends React.Component<CandidateDialogProps, CandidateDialogState>
{
    constructor(props: CandidateDialogProps)
    {
        super(props);

        this.state = this.generateState(props.candidate);
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public componentDidUpdate(prevProps: CandidateDialogProps, prevState: CandidateDialogState)
    {
        if(this.props.candidate && (!prevProps.candidate || prevProps.candidate !== this.props.candidate))
        {
            this.setState(this.generateState(this.props.candidate));
        }
        else if(!this.props.candidate && prevProps.candidate)
        {
            this.setState(this.generateState(this.props.candidate));
        }
    }

    public handleOnSave(e)
    {
        this.setState({
            positionError: false,
            genderError: false,
            previouslyElectedError: false,
            previouslyServedError: false
        });

        if(this.state.firstName 
            && this.state.lastName 
            && this.state.position
            && this.state.gender
            && typeof this.state.previouslyElected === 'boolean'
            && typeof this.state.previouslyServed === 'boolean')
        {
            if(this.state.mode === DialogMode.New)
            {
                let c = new Candidate();
                c.municipality = this.props.municipality.id;
                c.firstName = this.state.firstName;
                c.lastName = this.state.lastName;
                c.gender = this.state.gender;
                c.position = this.state.position;
                c.ward = this.state.ward;
                c.previouslyElected = this.state.previouslyElected;
                c.previouslyServed = this.state.previouslyServed;
                c.order = this.state.order && this.state.order !== '0' ? parseInt(this.state.order) : null;

                this.setState({ isLoading: true });

                AMMDataService.current().createCandidate(c).then(() => 
                {
                    this.setState({ isLoading: false });

                    if(this.props.onSaved)
                        this.props.onSaved();
                    else
                        this.props.onClose();

                    this.setState(this.generateState(null));
                });
            }
            else if(this.state.mode === DialogMode.Edit)
            {
                let updateData = {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    gender: this.state.gender,
                    position: this.state.position,
                    ward: this.state.ward,
                    previouslyElected: this.state.previouslyElected,
                    previouslyServed: this.state.previouslyServed,
                    order: this.state.order && this.state.order !== '0' ? parseInt(this.state.order) : null
                };

                this.setState({ isLoading: true });

                AMMDataService.current().updateCandidate(this.props.candidate.id, updateData).then(() =>
                {
                    this.setState({ isLoading: false });

                    if(this.props.onSaved)
                        this.props.onSaved();
                    else
                        this.props.onClose();
                });
            }
        }
        else
        {
            if(!this.state.position)
            {
                this.setState({ positionError: true });
            }

            if(!this.state.gender)
            {
                this.setState({ genderError: true });
            }

            if(typeof this.state.previouslyElected !== 'boolean')
            {
                this.setState({ previouslyElectedError: true });
            }

            if(typeof this.state.previouslyServed !== 'boolean')
            {
                this.setState({ previouslyServedError: true });
            }
        }

        e.preventDefault();
    }

    public render() 
    {
        let title = '';
        let button = '';
        let wardItems = [];

        if(this.state.mode === DialogMode.New)
        {
            title = 'New Candidate';
            button = 'Create';
        }
        else
        {
            title = 'Edit Candidate';
            button = 'Save';
        }

        if(this.props.municipality)
        {
            wardItems = this.props.municipality.wards.map((w) => 
            {
                return (<MenuItem key={w.name} value={w.name}>{w.name}</MenuItem>);
            });

            wardItems.unshift(<MenuItem key="At Large" value="At Large">At Large</MenuItem>);
        }

        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogSpinner showSpinner={this.state.isLoading}>
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <form onSubmit={this.handleOnSave.bind(this)} className="amm-dialog-form">
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            </DialogContentText>

                            <TextField
                                required
                                autoFocus
                                fullWidth
                                autoComplete="off"
                                label="First Name"
                                name="firstName"
                                placeholder="Enter first name"
                                type="text"
                                value={this.state.firstName}
                                onChange={(e) => this.setState({ firstName: e.target.value })}
                                disabled={this.state.isLoading}
                                margin="normal" />

                            <TextField
                                required
                                fullWidth
                                autoComplete="off"
                                label="Last Name"
                                name="lastName"
                                placeholder="Enter last name"
                                type="text"
                                value={this.state.lastName}
                                onChange={(e) => this.setState({ lastName: e.target.value })}
                                disabled={this.state.isLoading}
                                margin="normal" />

                            <TextField
                                required
                                fullWidth
                                select
                                error={this.state.positionError}
                                label="Position"
                                name="position"
                                value={this.state.position}
                                onChange={(e) => this.setState({ position: e.target.value })}
                                placeholder="Select a position"
                                disabled={this.state.isLoading}
                                margin="normal">
                                <MenuItem key="Mayor" value="Mayor">Mayor</MenuItem>
                                <MenuItem key="Reeve" value="Reeve">Reeve</MenuItem>
                                <MenuItem key="Head of Council" value="Head of Council">Head of Council</MenuItem>
                                <MenuItem key="Councillor" value="Councillor">Councillor</MenuItem>
                                <MenuItem key="LUD" value="LUD">LUD</MenuItem>
                            </TextField>

                            { (this.state.position === 'Councillor' || this.state.position === 'LUD') &&
                                <TextField
                                    fullWidth
                                    select
                                    label="Ward"
                                    name="ward"
                                    value={this.state.ward}
                                    onChange={(e) => this.setState({ ward: e.target.value })}
                                    placeholder="Select a ward"
                                    disabled={this.state.isLoading}
                                    margin="normal">
                                    {wardItems}
                                </TextField>
                            }

                            <FormControl fullWidth margin="normal" required error={this.state.genderError}>
                                <FormLabel>Gender</FormLabel>
                                <RadioGroup
                                    name="gender"
                                    value={this.state.gender}
                                    onChange={(e: any) => { this.setState({ gender: e.target.value }); }}>
                                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                </RadioGroup>
                                {
                                    this.state.genderError &&
                                    <FormHelperText>Please select a gender</FormHelperText>
                                }
                            </FormControl>

                            <FormControl fullWidth margin="normal" required error={this.state.previouslyServedError}>
                                <FormLabel>Did this candidate serve on the previous municipal council (2018-2022)?</FormLabel>
                                <RadioGroup
                                    name="served"
                                    value={(typeof this.state.previouslyServed === 'boolean' ? this.state.previouslyServed.toString() : null)}
                                    onChange={(e: any) => { this.setState({ previouslyServed: e.target.value === 'true' ? true : false }); }}>
                                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="false" control={<Radio />} label="No" />
                                </RadioGroup>
                                {
                                    this.state.previouslyServedError &&
                                    <FormHelperText>Please select a value</FormHelperText>
                                }
                            </FormControl>

                            <FormControl fullWidth margin="normal" required error={this.state.previouslyElectedError}>
                                <FormLabel>Is this individual an incumbent for this particular position or a new candidate?</FormLabel>
                                <RadioGroup
                                    name="elected"
                                    value={(typeof this.state.previouslyElected === 'boolean' ? this.state.previouslyElected.toString() : null)}
                                    onChange={(e: any) => { this.setState({ previouslyElected: e.target.value === 'true' ? true : false }); }}>
                                    <FormControlLabel value="true" control={<Radio />} label="Incumbent" />
                                    <FormControlLabel value="false" control={<Radio />} label="New Candidate" />
                                </RadioGroup>
                                {
                                    this.state.previouslyElectedError &&
                                    <FormHelperText>Please select a value</FormHelperText>
                                }
                            </FormControl>

                            <TextField
                                fullWidth
                                select
                                label="Sort Order"
                                name="order"
                                value={this.state.order}
                                onChange={(e) => this.setState({ order: e.target.value })}
                                placeholder="Sort Order"
                                disabled={this.state.isLoading}
                                margin="normal">
                                <MenuItem key={0} value={'0'}></MenuItem>
                                <MenuItem key={1} value={'1'}>1</MenuItem>
                                <MenuItem key={2} value={'2'}>2</MenuItem>
                                <MenuItem key={3} value={'3'}>3</MenuItem>
                                <MenuItem key={4} value={'4'}>4</MenuItem>
                                <MenuItem key={5} value={'5'}>5</MenuItem>
                                <MenuItem key={6} value={'6'}>6</MenuItem>
                                <MenuItem key={7} value={'7'}>7</MenuItem>
                                <MenuItem key={8} value={'8'}>8</MenuItem>
                                <MenuItem key={9} value={'9'}>9</MenuItem>
                                <MenuItem key={10} value={'10'}>10</MenuItem>
                                <MenuItem key={11} value={'11'}>11</MenuItem>
                                <MenuItem key={12} value={'12'}>12</MenuItem>
                                <MenuItem key={13} value={'13'}>13</MenuItem>
                                <MenuItem key={14} value={'14'}>14</MenuItem>
                                <MenuItem key={15} value={'15'}>15</MenuItem>
                                <MenuItem key={16} value={'16'}>16</MenuItem>
                                <MenuItem key={17} value={'17'}>17</MenuItem>
                                <MenuItem key={18} value={'18'}>18</MenuItem>
                                <MenuItem key={19} value={'19'}>19</MenuItem>
                                <MenuItem key={20} value={'20'}>20</MenuItem>
                            </TextField>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleOnClose.bind(this)} color="default" disabled={this.state.isLoading}>Cancel</Button>
                            <Button type="submit" color="primary" variant="contained" disabled={this.state.isLoading}>{button}</Button>
                        </DialogActions>
                    </form>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }

    private generateState(candidate: Candidate): CandidateDialogState
    {
        let state: CandidateDialogState;

        if(candidate)
        {
            state = {
                mode: DialogMode.Edit,
                firstName: candidate.firstName,
                lastName: candidate.lastName,
                gender: candidate.gender,
                position: candidate.position,
                ward: candidate.ward,
                previouslyServed: candidate.previouslyServed,
                previouslyElected: candidate.previouslyElected,
                order: candidate.order ? candidate.order.toString() : '0',
                isLoading: false,
                positionError: false,
                genderError: false,
                previouslyElectedError: false,
                previouslyServedError: false
            };
        }
        else
        {
            state = {
                mode: DialogMode.New,
                firstName: '',
                lastName: '',
                gender: '',
                position: '',
                ward: 'At Large',
                previouslyServed: null,
                previouslyElected: null,
                order: '0',
                isLoading: false,
                positionError: false,
                genderError: false,
                previouslyElectedError: false,
                previouslyServedError: false
            };
        }

        return state;
    }
}
