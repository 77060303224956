import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Municipality } from "../dto/Municipality";
import { AMMDataService } from "../api/AMMDataService";
import { DialogSpinner } from "../components/DialogSpinner";

export interface WardDeleteDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onDeleted?:() => void;

    municipality: Municipality;
    wardName: string;
}

export interface WardDeleteDialogState 
{
    isLoading: boolean;
}

export class WardDeleteDialog extends React.Component<WardDeleteDialogProps, WardDeleteDialogState>
{
    constructor(props: WardDeleteDialogProps)
    {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public handleOnDelete()
    {
        this.setState({ isLoading: true });

        AMMDataService.current().getMunicipality(this.props.municipality.id).then((muni) => 
        {
            const wards = muni.wards;
            const foundWards = wards.filter(w => w.name === this.props.wardName);
            const index = foundWards.length > 0 ? wards.indexOf(foundWards[0]) : -1;

            if(index >= 0)
            {
                wards.splice(index, 1);
                AMMDataService.current().updateMunicipality(muni.id, { wards: wards }).then(() => 
                {
                    this.setState({ isLoading: false });

                    if(this.props.onDeleted)
                        this.props.onDeleted();
                    else
                        this.props.onClose();
                });
            }
            else
            {
                this.setState({ isLoading: false });
                this.props.onClose();
            }
        });
    }

    public render() 
    {
        let wardName = '';

        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogSpinner showSpinner={this.state.isLoading}>
                    <DialogTitle id="alert-dialog-title">{`Delete ${this.props.wardName}?`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Are you sure you want to delete this ward / LUD?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleOnClose.bind(this)} color="default" autoFocus>Cancel</Button>
                        <Button onClick={this.handleOnDelete.bind(this)} color="primary" variant="contained" style={{ backgroundColor: '#f44336' }}>Delete</Button>
                    </DialogActions>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }
}
