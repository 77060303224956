import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Account } from "../dto/Account";
import { AMMDataService } from "../api/AMMDataService";
import { DialogSpinner } from "../components/DialogSpinner";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

export interface AccountChangeNameDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onSave?:() => void;

    account: Account;
}

export interface AccountChangeNameDialogState 
{
    isLoading: boolean;
    name: string;
}

export class AccountChangeNameDialog extends React.Component<AccountChangeNameDialogProps, AccountChangeNameDialogState>
{
    constructor(props: AccountChangeNameDialogProps)
    {
        super(props);

        this.state = {
            name: props.account ? props.account.name : '',
            isLoading: false
        };
    }

    public componentDidUpdate(prevProps: AccountChangeNameDialogProps, prevState: AccountChangeNameDialogState)
    {
        if(this.props.account && (!prevProps.account || prevProps.account !== this.props.account))
        {
            this.setState({ name: this.props.account.name });
        }
    }

    public handleSave(e)
    {
        this.setState({ isLoading: true });

        AMMDataService.current().updateAccount(this.props.account.uid, { name: this.state.name }).then(() => 
        {
            this.setState({ isLoading: false });

            if(this.props.onSave)
                this.props.onSave();
            else
                this.props.onClose();
        });

        e.preventDefault();
    }

    public render() 
    {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogSpinner showSpinner={this.state.isLoading}>
                    <form onSubmit={this.handleSave.bind(this)} autoComplete="off">
                        <DialogTitle id="alert-dialog-title">{`Change name for ${this.props.account ? this.props.account.name : ''}`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            </DialogContentText>

                            <FormControl fullWidth margin="normal">
                                <TextField
                                    required
                                    autoFocus
                                    fullWidth
                                    autoComplete="off"
                                    label="Name"
                                    name="name"
                                    placeholder="Enter display name"
                                    type="text"
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    disabled={this.state.isLoading}
                                    margin="normal" />
                            </FormControl>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleOnClose.bind(this)} color="default" autoFocus>Cancel</Button>
                            <Button type="submit" color="primary" variant="contained">Save</Button>
                        </DialogActions>
                    </form>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }
}
