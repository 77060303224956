import { Component } from "react";
import { Footer } from "../components/Footer";
import { getAuth } from "firebase/auth";

import CircularProgress from '@material-ui/core/CircularProgress';
import { RouteComponentProps } from "react-router-dom";

export interface LogoutProps extends RouteComponentProps<any>
{

}

export interface LogoutState 
{

}

export class Logout extends Component<LogoutProps, LogoutState>
{
    constructor(props: LogoutProps) 
    {
        super(props);

        this.state = {

        };
    }

    public componentDidMount() 
    {
        const auth = getAuth();
        auth.signOut().then(() => 
        {
            this.props.history.replace('/admin/login');
        });
    }

    public componentWillUnmount() 
    {
        
    }

    public render() 
    {
        return (
            <div className="page-login">

                <div className="card-login">
                    <div className="logo-container">
                        <img alt="AMM Logo" src="/images/amm.png" />
                    </div>       

                    <div className="form-loading">
                        <CircularProgress color="secondary" />
                        <div>Logging out</div>
                    </div>           

                </div>

                <Footer />
            </div>
        );
    }
}
