import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Account } from "../dto/Account";
import { AMMDataService } from "../api/AMMDataService";
import { DialogSpinner } from "../components/DialogSpinner";

export interface AccountPasswordResetDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onReset?:() => void;

    account: Account;
}

export interface AccountPasswordResetDialogState 
{
    isLoading: boolean;
}

export class AccountPasswordResetDialog extends React.Component<AccountPasswordResetDialogProps, AccountPasswordResetDialogState>
{
    constructor(props: AccountPasswordResetDialogProps)
    {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public handlePasswordReset()
    {
        this.setState({ isLoading: true });

        AMMDataService.current().resetPassword(this.props.account.uid).then(() => 
        {
            this.setState({ isLoading: false });

            if(this.props.onReset)
                this.props.onReset();
            else
                this.props.onClose();
        });
    }

    public render() 
    {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogSpinner showSpinner={this.state.isLoading} message="Resetting password. Please wait...">
                    <DialogTitle id="alert-dialog-title">{`Reset password for ${this.props.account ? this.props.account.name : ''}?`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`This will generate a new password and send it to the SEO's email address. The SEO's previous password will become unusable.`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleOnClose.bind(this)} color="default" autoFocus>Cancel</Button>
                        <Button onClick={this.handlePasswordReset.bind(this)} color="primary" variant="contained" style={{ backgroundColor: '#f44336' }}>Reset Password</Button>
                    </DialogActions>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }
}
