import { FunctionComponent } from "react";
import { TopNavigation } from "../components/TopNavigation";
import { Footer } from "../components/Footer";
import i18n from "../utils/i18n";

export interface ResultsDashboardProps 
{
}

const ResultsDashboard: FunctionComponent = (props: ResultsDashboardProps) =>
{
    const dashboardUrl = i18n.language.indexOf('en') === 0 ?
        process.env.REACT_APP_PBIREPORT_URL :
        process.env.REACT_APP_PBIREPORT_URL_FR;

    return (
        <div className="page-reports">
            {/* <link rel="stylesheet" href="/styles/map.css" /> */}
            <div className="nav-container">
                <TopNavigation />
            </div>

            <div className="reports-page-content">
                <div className="card">
                    {/* <h1>Election Results Dashboard</h1> */}
                    <div className="report-container" style={{ overflow: "hidden", paddingTop: "56.25%", position: "relative" }}>

                        <iframe 
                            style={{ height: "100%", width: "100%", position: "absolute", top: 0, border: 0, left: 0 }}
                            title="AMM Public Dashboard" width="600" height="373.5" src={dashboardUrl} frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    ); 
}

export default ResultsDashboard;
