import { Component } from "react";
import { Portal } from "./Portal";

export interface MenuOverlayProps
{
    className?: string;
    isOpen: boolean;
    onDismiss: () => void;
}

export interface MenuOverlayState
{
    entering: boolean;
    containerOpen: boolean;
}

export class MenuOverlay extends Component<MenuOverlayProps, MenuOverlayState>
{
    private timeoutId = null;
    private overlayElement = null;

    constructor(props: MenuOverlayProps)
    {
        super(props);

        this.state = {
            entering: false,
            containerOpen: false
        };
    }

    public componentDidMount()
    {
        
    }

    public componentDidUpdate(prevProps: MenuOverlayProps, prevState: MenuOverlayState, snapshot)
    {
        if(this.props.isOpen === false && prevProps.isOpen === true)
        {
            this.timeoutId = setTimeout(() => {
                this.timeoutId = null;
                this.setState({ containerOpen: false });
            }, 500);
        }
        else if(this.props.isOpen === true && prevProps.isOpen === false)
        {
            if(this.timeoutId)
            {
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
            }
                
            this.setState({ containerOpen: true });
        }
    }

    public render()
    {
        let classes = 'component-overlay';
        let overlayStyles: React.CSSProperties = {};
        let overlayContentStyles: React.CSSProperties = {};

        var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        var x = Math.max(w, h) * 1.3;

        if(this.props.isOpen)
        {
            classes += ' open';

            overlayStyles = {
                width: `${x}px`,
                height: `${x}px`
            };
        }

        overlayContentStyles = {
            width: `${w}px`,
            height: `${h}px`
        }

        if(this.props.className)
            classes += ' ' + this.props.className;

        return (
            <Portal className={"overlay-container" + (this.state.containerOpen ? " open" : "")}>
                <div className={classes} style={overlayStyles}>
                    <div className="overlay-content" style={overlayContentStyles}>
                        {this.props.children}
                    </div>
                </div>
            </Portal>
        );
    }

    private onDismiss()
    {
        if(this.props.onDismiss)
            this.props.onDismiss();
    }
}