import firebase from "firebase/compat/app";

export class Account
{
    public id: string;

    public uid: string;
    public name: string;
    public email: string;
    public role: string;
    public municipalityCount?: number;

    public static fromDoc(doc: firebase.firestore.QueryDocumentSnapshot)
    {
        const data = doc.data();

        const a = new Account();
        a.id = doc.id;
        a.uid = data.uid;
        a.email = data.email;
        a.name = data.name;
        a.role = data.role;
        a.municipalityCount = data.municipalityCount ?? 0;

        return a;
    }

    public static roleString(account: Account)
    {
        if(account.role === 'admin')
            return 'AMM Administrator';
        else if(account.role === 'seo')
            return 'Senior Election Official'
        else
            return account.role;
    }
}