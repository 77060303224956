import { Component } from "react";

export interface NotFoundProps 
{

}

export interface NotFoundState 
{
}

export class NotFound extends Component<NotFoundProps, NotFoundState>
{
    constructor(props: NotFoundProps) 
    {
        super(props);

        this.state = {
        };
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public render() 
    {
        return (
            <div className="app">
                <h1>Not Found</h1>
                <p>That page was not found</p>
            </div>
        );
    }
}
