import { Municipality } from "../dto/Municipality";
import { CandidatesQuery } from "./CandidateQuery";
import AMMQuery from "./AMMQuery";
import { textChangeRangeIsUnchanged } from "typescript";

export class MunicipalityQuery
{
    private query: AMMQuery<Municipality>;
    private rootUrl: string;

    constructor(rootUrl?: string)
    {
        this.rootUrl = (rootUrl ?? '') + '/municipalities';
        this.query = new AMMQuery<Municipality>(this.rootUrl);
    }

    public add(data: Partial<Municipality>)
    {
        return this.query.add(data);
    }

    public get()
    {
        return this.query.get();
    }

    public getById(id: string)
    {
        return {
            update: (data: any) => { return this.update(id, data) },
            get: () => { return this.getOne(id); },
            delete: () => { return this.delete(id); },
            candidates: new CandidatesQuery(this.rootUrl + `/${id}`)
        };
    }

    private update(id: string, data: Partial<Municipality>)
    {
        return this.query.update(id, data);
    }

    private getOne(id: string)
    {
        return this.query.getById(id);
    }

    private delete(id: string)
    {
        return this.query.delete(id);
    }
}