import { Component } from "react";
import { Footer } from "../components/Footer";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { RouteComponentProps } from "react-router-dom";

export interface ForgotPasswordProps extends RouteComponentProps<any>
{
}

export interface ForgotPasswordState 
{
    email: string;
    errorMessage: string;
    isSubmitting: boolean;
    isComplete: boolean;
}

export class ForgotPassword extends Component<ForgotPasswordProps, ForgotPasswordState>
{
    constructor(props: ForgotPasswordProps) 
    {
        super(props);

        this.state = {
            email: '',
            errorMessage: '',
            isSubmitting: false,
            isComplete: false
        };
    }

    public componentDidMount() 
    {
    }

    public onResetPassword(e)
    {
        if(this.state.isSubmitting === false)
        {
            this.setState({ isSubmitting: true, errorMessage: null });

            const auth = getAuth();
            sendPasswordResetEmail(auth, this.state.email).then(() => 
            {
                this.setState({ isSubmitting: false, isComplete: true });
            }).catch((error) => 
            {
                let message = `Error: ${error.code}: ${error.message}`;
                if(error.code === 'auth/user-not-found')
                {
                    message = 'An account with this email address does not exist';
                }
                else if(error.code === 'auth/invalid-email')
                {
                    message = 'Please enter a valid email address';
                }

                this.setState({ 
                    isSubmitting: false,
                    errorMessage: message
                });
            });
        }

        e.preventDefault();
    }

    public componentWillUnmount() 
    {

    }

    public render() 
    {
        let formContent = null;

        if(this.state.isComplete === false)
        {
            let errorContent = null;

            if(this.state.errorMessage)
            {
                errorContent = (
                    <p className="error-message">{this.state.errorMessage}</p>
                );
            }

            formContent = (
                <form onSubmit={this.onResetPassword.bind(this)}>
                    <TextField
                        disabled={this.state.isSubmitting} 
                        autoFocus
                        fullWidth
                        label="Email"
                        name="email"
                        placeholder="Enter your email address"
                        type="email"
                        margin="dense"
                        onChange={this.handleEmailChange.bind(this)}
                    />

                    {errorContent}

                    <div className="login-button-container">

                        <Button
                            disabled={this.state.isSubmitting}
                            variant="contained" 
                            color="primary"
                            type="submit">
                            Reset Password
                        </Button>

                        <a className="forgot" href="/admin/login">Return to Login Page</a>

                    </div>
                    
                </form>
            );
        }
        else
        {
            formContent = (
                <div className="form-reset-success">
                    <p>We've sent password reset instructions to your email address:</p>
                    <p className="centered reset-email">{this.state.email}</p>
                    <p className="centered reset-link"><a href="/admin/login">Return to Login Page</a></p>
                </div>
            );
        }

        return (
            <div className="page-login">

                <div className="card-login">
                    <div className="logo-container">
                        <img src="/images/amm.png" />
                    </div>

                    <h1>AMM Administrator<br /> and SEO Password Reset</h1>

                    {formContent}

                </div>

                <Footer />
            </div>
        );
    }

    public handleEmailChange(event)
    {
        this.setState({ email: event.target.value });
    }
}
