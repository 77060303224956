import { Component } from "react";
import { Municipality } from '../dto/Municipality';
import { Panel } from './Panel';
import { MunicipalityListItem } from './MunicipalityListItem';
import { Logo } from './Logo';

import Button from '@material-ui/core/Button';
import i18n from "../utils/i18n";

export interface MunicipalityListProps
{
    className?: string;
    isOpen: boolean;
    municipalities: Municipality[];
    recentMunicipalities: Municipality[];
    selectedMunicipality: Municipality;
    onSelectMunicipality: (m: Municipality) => void;
    onViewMunicipality: (m: Municipality) => void;
    onRequestClose?: () => void;
}

export interface MunicipalityListState
{
    selectedDistrict: string;
}

export class MunicipalityList extends Component<MunicipalityListProps, MunicipalityListState>
{
    constructor(props: MunicipalityListProps)
    {
        super(props);

        this.state = {
            selectedDistrict: 'All Districts'
        };
    }

    public componentDidMount()
    {
    }

    public componentWillUnmount()
    {
    }

    public render()
    {
        let municipalityItems = [];
        let recentItems = [];
        let selectedValue = this.props.selectedMunicipality ? this.props.selectedMunicipality.id : -1;

        if(this.props.municipalities)
        {
            let municipalities = this.props.municipalities.filter((m) => 
                this.state.selectedDistrict === 'All Districts' || m.district === this.state.selectedDistrict);

            municipalityItems = municipalities.sort(this.sortMunicipalities).map((m) => 
            {
                return <option key={m.id} value={m.id}>{m.getName()} ({m.getTypeString()})</option>
            });

            municipalityItems.unshift(<option key="0">{i18n.t('MunicipalityList-SelectAMunicipality')}</option>);
        }

        if(this.props.recentMunicipalities)
        {
            recentItems = this.props.recentMunicipalities.map((m) => 
            {
                let active = false;
                if(this.props.selectedMunicipality && this.props.selectedMunicipality.id === m.id)
                    active = true;

                return <MunicipalityListItem 
                    key={m.id}
                    className={active ? 'active' : ''}
                    onClick={(e) => { this.onRecentItemClicked(e, m); }} 
                    municipality={m} />
            });
        }

        return (
            <Panel className={"component-municipality-list" + (this.props.className ? ' ' + this.props.className : '') + (this.props.isOpen ? ' open' : '') }>
                <div className="panel-content">

                    <div className="panel-actions">
                        <Button 
                            onClick={(e) => { if(this.props.onRequestClose) { this.props.onRequestClose() } }}
                            variant="outlined"
                            color="primary">
                            {i18n.t('MunicipalityList-Hide')}
                        </Button>
                    </div>

                    <div className="logo-container">
                        <Logo showText={recentItems.length > 0} />
                    </div>

                    { recentItems.length === 0 &&
                        <section className="title">
                            <h1>{i18n.t('Title-1')}<br />{i18n.t('Title-2')}</h1>
                            <p>{i18n.t('MunicipalityList-Description')}</p>
                        </section>
                    }

                    <section>
                        <h3>{i18n.t('MunicipalityList-Find')}</h3>
                        <select 
                            className="district-select"
                            value={this.state.selectedDistrict}
                            onChange={(e) => { this.setState({ selectedDistrict: e.target.value }); }}>
                            <option value="All Districts">{i18n.t('MunicipalityList-AllDistricts')}</option>
                            <option value="Central">{i18n.t('District-Central')}</option>
                            <option value="Eastern">{i18n.t('District-Eastern')}</option>
                            <option value="Interlake">{i18n.t('District-Interlake')}</option>
                            <option value="Midwestern">{i18n.t('District-Midwestern')}</option>
                            <option value="Northern">{i18n.t('District-Northern')}</option>
                            <option value="Parkland">{i18n.t('District-Parkland')}</option>
                            <option value="Western">{i18n.t('District-Western')}</option>
                            <option value="Winnipeg">{i18n.t('District-Winnipeg')}</option>
                        </select>

                        <select 
                            value={selectedValue}
                            className="municipality-select" 
                            onChange={this.onSelectChanged.bind(this)}>
                            {municipalityItems}
                        </select>

                    </section>

                    { recentItems.length > 0 &&
                        <h3>{i18n.t('MunicipalityList-Recent')}</h3>
                    }
                    { recentItems.length > 0 &&
                        <section className="recents-list">
                            {recentItems}
                        </section>    
                    }

                    <Button 
                        fullWidth
                        className="mobile-button"
                        style={{ height: '50px', marginTop: '20px' }}
                        variant="contained" 
                        onClick={(e) => { if(this.props.onRequestClose) { this.props.onRequestClose() } }}
                        color="primary">{i18n.t('MunicipalityList-ShowMap')}</Button>

                </div>
            </Panel>
        );
    }

    private onRecentItemClicked(e, m: Municipality)
    {
        if(this.props.onViewMunicipality)
            this.props.onViewMunicipality(m);
    }

    private onSelectChanged(e)
    {
        if(this.props.onSelectMunicipality)
        {
            let selected = this.props.municipalities.filter(m => m.id === e.target.value);
            if(selected && selected.length > 0)
            {
                this.props.onSelectMunicipality(selected[0]);
            }

            if(this.props.onRequestClose)
                this.props.onRequestClose();
        }
    }

    private sortMunicipalities(a: Municipality, b: Municipality)
    {
        if(a.getName() < b.getName()) { return -1; }
        else if(a.getName() > b.getName()) { return 1; }
        else { return 0; }
    }
}