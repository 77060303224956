import { Component } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

export interface SpinnerProps
{
    className?: string;
}

export interface SpinnerState
{
    entering: boolean;
}

export class Spinner extends Component<SpinnerProps, SpinnerState>
{
    constructor(props: SpinnerProps)
    {
        super(props);

        this.state = {
            entering: true
        }
    }

    public componentDidMount()
    {
        
    }

    public render()
    {
        return (
            <div className={'component-spinner' + (this.props.className ? ` ${this.props.className}`: '')}>
                <CircularProgress color="secondary" />
                {this.props.children}
            </div>
        );
    }
}