import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { TextField, MenuItem } from "@material-ui/core";
import { Municipality } from "../dto/Municipality";
import { AMMDataService } from "../api/AMMDataService";
import { DialogSpinner } from "../components/DialogSpinner";

enum DialogMode 
{
    New,
    Edit
};

export interface MunicipalityDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onSaved?: () => void;
    onCreated?: (municipalityId: string) => void;

    municipality?: Municipality;
}

export interface MunicipalityDialogState 
{
    name: string;
    frName: string;
    type: string;
    district: string;
    targetResults: string;

    mode: DialogMode;
    isLoading: boolean;
}

export class MunicipalityDialog extends React.Component<MunicipalityDialogProps, MunicipalityDialogState>
{
    constructor(props: MunicipalityDialogProps)
    {
        super(props);

        if(props.municipality)
        {
            let muni = props.municipality;

            this.state = {
                mode: DialogMode.Edit,
                name: muni.name,
                frName: muni.frName,
                type: muni.type,
                targetResults: muni.targetResults,
                district: muni.district,
                isLoading: false
            };
        }
        else
        {
            this.state = {
                mode: DialogMode.New,
                name: '',
                frName: '',
                type: '',
                district: '',
                targetResults: '',
                isLoading: false
            };
        }
    }

    public componentDidMount() 
    {

    }

    public componentWillUnmount() 
    {

    }

    public componentDidUpdate(prevProps, prevState)
    {
        if(this.props.municipality && (!prevProps.municipality || prevProps.municipality !== this.props.municipality))
        {
            let muni = this.props.municipality;
            this.setState({
                name: muni.name,
                frName: muni.frName || '',
                type: muni.type,
                district: muni.district,
                targetResults: muni.targetResults,
                mode: DialogMode.Edit
            });
        }
        else if(!this.props.municipality && prevProps.municipality)
        {
            this.setState({
                name: '',
                frName: '',
                type: '',
                district: '',
                targetResults: '',
                mode: DialogMode.New
            });
        }
    }

    public async handleOnSave(e)
    {
        if(this.state.name && this.state.type && this.state.district)
        {
            if(this.state.mode === DialogMode.New)
            {
                let muni = new Municipality();
                muni.name = this.state.name;
                muni.frName = this.state.frName;
                muni.type = this.state.type;
                muni.district = this.state.district;
                muni.targetResults = this.state.targetResults;

                this.setState({ isLoading: true });

                AMMDataService.current().createMunicipality(muni).then((m) => 
                {
                    this.setState({ isLoading: false });

                    if(this.props.onCreated)
                        this.props.onCreated(m.id);
                    else
                        this.props.onClose();
                });
            }
            else if(this.state.mode === DialogMode.Edit)
            {
                const updateData = {
                    name: this.state.name,
                    frName: this.state.frName,
                    type: this.state.type,
                    district: this.state.district,
                    targetResults: this.state.targetResults
                };

                this.setState({ isLoading: true });

                AMMDataService.current().updateMunicipality(this.props.municipality.id, updateData).then(() =>
                {
                    this.setState({ isLoading: false });

                    if(this.props.onSaved)
                        this.props.onSaved();
                    else
                        this.props.onClose();
                });
            }
        }

        e.preventDefault();
    }

    public render() 
    {
        let title = '';
        let button = '';

        if(this.state.mode === DialogMode.New)
        {
            title = 'New Municipality';
            button = 'Create';
        }
        else
        {
            title = 'Edit Municipality';
            button = 'Save';
        }

        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                scroll="paper"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogSpinner showSpinner={this.state.isLoading}>
                    <form onSubmit={this.handleOnSave.bind(this)}>
                        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            </DialogContentText>

                            <TextField
                                required
                                autoFocus
                                fullWidth
                                autoComplete="off"
                                label="Name"
                                name="name"
                                placeholder="Enter a municipality name"
                                type="text"
                                value={this.state.name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                                disabled={this.state.isLoading}
                                margin="dense" />

                            <TextField
                                fullWidth
                                autoComplete="off"
                                label="Name (French)"
                                name="name-french"
                                placeholder="French translation of the municipality name"
                                helperText="If no French translation is provided, the English name will be displayed."
                                type="text"
                                value={this.state.frName}
                                onChange={(e) => this.setState({ frName: e.target.value })}
                                disabled={this.state.isLoading}
                                margin="dense" />

                            <TextField
                                required
                                fullWidth
                                select
                                label="Type"
                                name="type"
                                value={this.state.type}
                                onChange={(e) => this.setState({ type: e.target.value })}
                                placeholder="Select a municipality type"
                                disabled={this.state.isLoading}
                                margin="dense">
                                <MenuItem key="City" value="City">City</MenuItem>
                                <MenuItem key="Town" value="Town">Town</MenuItem>
                                <MenuItem key="Rural Municipality" value="Rural Municipality">Rural Municipality</MenuItem>
                                <MenuItem key="Municipality" value="Municipality">Municipality</MenuItem>
                                <MenuItem key="Village" value="Village">Village</MenuItem>
                                <MenuItem key="Local Government District" value="Local Government District">Local Government District</MenuItem>
                            </TextField>

                            <TextField
                                required
                                fullWidth
                                select
                                label="District"
                                name="district"
                                value={this.state.district}
                                onChange={(e) => this.setState({ district: e.target.value })}
                                placeholder="Select a district"
                                disabled={this.state.isLoading}
                                margin="dense">
                                <MenuItem key="Central" value="Central">Central</MenuItem>
                                <MenuItem key="Eastern" value="Eastern">Eastern</MenuItem>
                                <MenuItem key="Interlake" value="Interlake">Interlake</MenuItem>
                                <MenuItem key="Midwestern" value="Midwestern">Midwestern</MenuItem>
                                <MenuItem key="Northern" value="Northern">Northern</MenuItem>
                                <MenuItem key="Parkland" value="Parkland">Parkland</MenuItem>
                                <MenuItem key="Western" value="Western">Western</MenuItem>
                                <MenuItem key="Winnipeg" value="Winnipeg">Winnipeg</MenuItem>
                            </TextField>

                            {/* <TextField
                                fullWidth
                                label="Target Results"
                                name="targetResults"
                                placeholder=""
                                type="text"
                                helperText="Describe the target results for this location (i.e. Which positions are the people voting for)"
                                multiline
                                value={this.state.targetResults}
                                onChange={(e) => this.setState({ targetResults: e.target.value })}
                                disabled={this.state.isLoading}
                                margin="dense" /> */}

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleOnClose.bind(this)} color="default" disabled={this.state.isLoading}>Cancel</Button>
                            <Button type="submit" color="primary" variant="contained" disabled={this.state.isLoading}>{button}</Button>
                        </DialogActions>
                    </form>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }
}
