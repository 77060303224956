import { Component } from "react";
import { UserContext } from "../pages-admin/UserContext";

export interface RoleTrimProps
{
    allowedRoles: string[] | string;
}

export interface RoleTrimState
{

}

export class RoleTrim extends Component<RoleTrimProps, RoleTrimState>
{
    constructor(props: RoleTrimProps)
    {
        super(props);

        this.state = {
        }
    }

    public componentDidMount()
    {
        
    }

    public render()
    {
        return (
            <UserContext.Consumer>
            {
                (user) => 
                {
                    if(typeof this.props.allowedRoles === 'string')
                    {
                        return this.props.allowedRoles.split(',').indexOf(user ? user.role : null) >= 0 ? this.props.children : null;
                    }
                    else
                    {
                        return this.props.allowedRoles.indexOf(user ? user.role : null) >= 0 ? this.props.children : null
                    }
                }
            }
            </UserContext.Consumer>
        );
    }
}
