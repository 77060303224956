import { Component } from "react";
import { Footer } from "../components/Footer";
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';

import { getAuth, onAuthStateChanged } from "firebase/auth";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { MunicipalitiesTabWithContext } from "./MunicipalitiesTab";
import { SEOTab } from "./SEOTab";
import { SEODetails } from "./SEODetails";
import { MunicipalityDetails } from "./MunicipalityDetails";
import { AMMDataService } from "../api/AMMDataService";
import { Account } from "../dto/Account";
import { Spinner } from "../components/Spinner";
import { UserContext } from "./UserContext";
import { VotingResults } from "./VotingResults";
import { Logo } from "../components/Logo";
import i18n from "../utils/i18n";

export interface DashboardProps extends RouteComponentProps<any>
{

}

export interface DasboardState 
{
    tabIndex: number;
    username: string;
    role: string;
    currentUser: Account;
    isLoading: boolean;
}

export class Dashboard extends Component<DashboardProps, DasboardState>
{
    constructor(props: DashboardProps) 
    {
        super(props);

        this.state = {
            tabIndex: props.location.pathname.indexOf('accounts') >= 0 ? 1 : 0,
            username: '',
            role: '',
            currentUser: null,
            isLoading: true
        };
    }

    public componentDidMount() 
    {
        i18n.changeLanguage('en');

        const auth = getAuth();
        onAuthStateChanged(auth, (user) => 
        {
            if(!user)
            {
                this.props.history.replace('/admin/login');
            }
            else
            {
                AMMDataService.current().getUserInfo(user.uid).then((account) => 
                {
                    this.setState({ 
                        currentUser: account,
                        role: Account.roleString(account),
                        username: account.name,
                        isLoading: false
                    });
                }).catch(error => 
                {
                    console.log(error);
                });
            }
        });
    }

    public componentDidUpdate(prevProps: DashboardProps, prevState: DasboardState)
    {
        // if(this.state.tabIndex != prevState.tabIndex)
        // {
        //     switch(this.state.tabIndex)
        //     {
        //         case 0: this.props.history.push(`${this.props.match.path}/municipalities`); break;
        //         case 1: this.props.history.push(`${this.props.match.path}/accounts`); break;
        //     }
        // }
        if(this.props.location.pathname.indexOf('municipalities') >= 0 && prevProps.location.pathname.indexOf('municipalities') === -1)
        {
            this.setState({ tabIndex: 0 });
        }
        else if(this.props.location.pathname.indexOf('accounts') >= 0 && prevProps.location.pathname.indexOf('accounts') === -1)
        {
            this.setState({ tabIndex: 1 });
        }
    }

    public componentWillUnmount() 
    {

    }

    public render() 
    {
        let path = this.props.match.path;

        let tabStyle: React.CSSProperties = {
            color: '#292D30',
            textTransform: 'none',
            fontSize: '1.6rem'
        }

        return (
            <UserContext.Provider value={this.state.currentUser}>
                <div className="page-dashboard">
                    <div className="nav">
                        <div className="nav-info">
                            {/* <div className="badge-container">
                                <a href="/" className="badge">
                                    <div>2018</div>
                                    <div>MME</div>
                                </a>
                            </div>
                            <div className="title">2018 Manitoba<br />Municipal Elections</div> */}
                            <div className="logo-container">
                                <Logo />
                            </div>
                            <div className={'user-container' + (this.state.username ? '' : ' hidden')}>
                                <div className="name">{this.state.username}</div>
                                <div className="role">Logged in as {this.state.role}</div>
                                <div className="user-buttons"><a href="/">Back to Homepage</a><span className="divider">|</span><a href="/admin/logout">Log out</a></div>
                            </div>
                        </div>

                        { this.state.isLoading === false && 
                            <Tabs
                                value={this.state.tabIndex}
                                onChange={this.handleTabChange.bind(this)}
                                indicatorColor="secondary">

                                <Tab style={tabStyle} label="Municipalities" />

                                { this.state.currentUser && this.state.currentUser.role === 'admin' &&
                                    <Tab style={tabStyle} label="SEO Accounts" />
                                }
                                    
                            </Tabs>
                        }

                    </div>

                    <div className="tab-content">
                        { this.state.isLoading && 
                            <Spinner /> 
                        }

                        { !this.state.isLoading &&
                            <Switch>
                                <Redirect exact path={path} to={`${path}/municipalities`} />
                                <Route path={`${path}/municipalities/:municipalityId/results`} component={VotingResults} />
                                <Route path={`${path}/municipalities/:municipalityId`} component={MunicipalityDetails} />
                                <Route path={`${path}/municipalities`} component={MunicipalitiesTabWithContext} />
                                <Route path={`${path}/accounts/:accountId`} component={SEODetails} />
                                <Route path={`${path}/accounts`} component={SEOTab} />
                            </Switch>
                        }
                    </div>

                    <Footer />
                </div>
            </UserContext.Provider>
        );
    }

    private handleTabChange(event, value)
    {
        this.setState({ tabIndex: value });

        switch(value)
        {
            case 0: this.props.history.push(`${this.props.match.path}/municipalities`); break;
            case 1: this.props.history.push(`${this.props.match.path}/accounts`); break;
        }
    }
}
