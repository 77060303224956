import { Component } from "react";
import { MenuOverlay } from "./MenuOverlay";
import { Footer } from "./Footer";
import { Logo } from "./Logo";
import i18n from "../utils/i18n";

import "./TopNavigation.scss";

export interface TopNavigationProps
{
    className?: string;
}

export interface TopNavigationState
{
    entering: boolean;
    isOpen: boolean;
}

export class TopNavigation extends Component<TopNavigationProps, TopNavigationState>
{
    constructor(props: TopNavigationProps)
    {
        super(props);

        this.state = {
            entering: false,
            isOpen: false
        };
    }

    public componentDidMount()
    {

    }

    private onClickFacebook(e)
    {
        const url = encodeURIComponent('https://mbvotes.ca');
        const opts = 'status=1,width=575,height=400,top=0,left=0';
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, 'facebook-share-dialog', opts);

        e.preventDefault();
        return false;
    }

    public render()
    {
        //let mailtoString = 'mailto:?' + encodeURIComponent('subject=2018 Manitoba Municipal Elections&body=Follow results of the 2018 Manitoba Municipal Elections from AMM – https://mbvotes.ca');
        //let mailtoString = `mailto:?subject=2018 Manitoba Municipal Elections&body=Follow results of the 2018 Manitoba Municipal Elections from AMM – https://mbvotes.ca`;
        const twitterString = `https://twitter.com/intent/tweet?text=Follow%20results%20of%20the%202022%20Manitoba%20Municipal%20Elections%20from%20AMM%20%E2%80%93%20https%3A%2F%2Fmbvotes.ca`;

        const fbButton = (
            <a key="0" href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://mbvotes.ca')}`} onClick={this.onClickFacebook.bind(this)}><i className="fab fa-facebook" /></a>
        );

        const shareButtons = [
            fbButton,
            <a key="1" href={twitterString}><i className="fab fa-twitter" /></a>,
            // <a href={mailtoString}><i className="fa fa-envelope" /></a>
        ];

        const enSelectedClass = i18n.language.indexOf('en') === 0 ? 'selected' : '';
        const frSelectedClass = i18n.language.indexOf('fr') === 0 ? 'selected' : '';

        return (
            <div className={'component-topnavigation' + (this.props.className ? ` ${this.props.className}`: '')}>

                <Logo />

                <div className="top-nav-separator"></div>

                <div>
                    <div className="menu-links">
                        <div className="menu-language-selector">
                            <a href="#" className={enSelectedClass} onClick={() => { i18n.changeLanguage('en'); }}>EN</a>
                            <a href="#" className={frSelectedClass} onClick={() => { i18n.changeLanguage('fr'); }}>FR</a>
                        </div>

                        <div className="menu-language-selector-mobile">
                                <a href="#" onClick={() => { i18n.language.startsWith('en') ? i18n.changeLanguage('fr') : i18n.changeLanguage('en'); }}>
                                    {i18n.language.startsWith('en') ? 'EN' : 'FR'}
                                </a>
                        </div>

                        <span>{i18n.t('Navigation-Share')}</span>
                        <span className="divider"></span>
                        {shareButtons}
                        <a href="#" className="menu-button" onClick={this.onMenuClick.bind(this)}><i className="fa fa-bars" /></a>
                    </div>

                    <MenuOverlay 
                        onDismiss={this.onMenuClick.bind(this)}
                        className="top-nav-menu"
                        isOpen={this.state.isOpen}>

                        <div className="nav-container">
                            <div className="component-topnavigation">
                                <Logo variant="light" />
                                <div className="top-nav-separator"></div>
                                <div>
                                    <div className="menu-links light">
                                        <span>{i18n.t('Navigation-Share')}</span>
                                        <span className="divider"></span>
                                        {shareButtons}
                                        <a href="#" className="menu-button" onClick={this.onMenuClick.bind(this)}><i className="fa fa-times" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="menu-container">
                            {/* <Link to={`/`}>Home</Link>
                            <Link to={`/about`}>About Us</Link>
                            <Link to={`/contact`}>Contact</Link>
                            <Link to={`/admin`}>Administrator and SEO Login</Link> */}
                            <div className="menu-navigation-links">
                                <a href="/">{i18n.t('Navigation-Home')}</a>
                                <a href="/about">{i18n.t('Navigation-About')}</a>
                                <a href="/contact">{i18n.t('Navigation-Contact')}</a>
                                {
                                    process.env['REACT_APP_YEAR'] === '2022' &&
                                    <>
                                        <a href="/dashboard">{i18n.t('Navigation-Dashboard')}</a>
                                        <a href="https://2018.mbvotes.ca">{i18n.t('Navigation-Historical-2018')}</a>
                                    </>
                                }
                                {
                                    process.env['REACT_APP_YEAR'] === '2018' &&
                                    <a href="https://mbvotes.ca">{i18n.t('Navigation-Historical-2022')}</a>
                                }
                                <a href="/admin">{i18n.t('Navigation-Admin')}</a>
                            </div>

                            <div className="language-picker">
                                <a className={enSelectedClass} href="#" onClick={() => { 
                                    i18n.changeLanguage('en'); // Automatically stores selection in localStorage
                                }}>English</a> 
                                <span>|</span>
                                <a className={frSelectedClass} href="#" onClick={() => { 
                                    i18n.changeLanguage('fr'); 
                                }}>Français</a>
                            </div>

                            <div className="list-links">
                                {shareButtons}
                            </div>

                        </div>

                        <Footer variant="light" />

                    </MenuOverlay>
                </div>
            </div>
        );
    }

    private selectLanguage(code: string)
    {
        i18n.changeLanguage(code); // Automatically stores selection in localStorage
    }
    
    private onMenuClick()
    {
        this.setState({ isOpen: !this.state.isOpen });
    }
}