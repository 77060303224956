import i18n from "../utils/i18n";
import Ward from "./Ward";

export class Municipality
{
    public id: string;
    public name: string;
    public frName: string;
    public type: string;
    public district: string;
    public resultsDeclared: boolean;
    public targetResults: string;
    public assigned: any;
    public wards: Ward[];

    constructor()
    {
        this.assigned = {};
        this.wards = [];
        this.resultsDeclared = false;
    }

    // public static fromDoc(doc: firebase.firestore.QueryDocumentSnapshot)
    // {
    //     const data = doc.data();

    //     const m = new Municipality();
    //     m.id = doc.id;
    //     m.name = data.name;
    //     m.type = data.type;
    //     m.district = data.district;
    //     m.resultsDeclared = data.resultsDeclared;
    //     //m.targetResults = data.targetResults;
    //     m.targetResults = '';
    //     m.assigned = data.assigned;
    //     m.wards = data.wards || [];

    //     m.wards.sort();

    //     return m;
    // }

    public static fromJson(obj: any)
    {
        const retval = new Municipality();
        Object.assign(retval, obj);
        return retval;
    }

    public getName()
    {
        if(i18n.language.indexOf('fr') === 0 && typeof this.frName === 'string' && this.frName.length > 0)
            return this.frName;
        else
            return this.name;
    }

    public getTypeString()
    {
        return i18n.t(`MuniTypes-${this.type.replace(/\s+/gi, '')}`, { defaultValue: this.type });
    }

    public static getWard(muni: Municipality, name: string)
    {
        const retval = muni.wards.filter(w => w.name === name);
        return retval.length > 0 ? retval[0] : null;
    }

    public static typeString(value: string)
    {
        return i18n.t(`MuniTypes-${value.replace(/\s+/gi, '')}`, { defaultValue: value });
    }

    // public static fromJsonOld(json: any)
    // {
    //     let id = '';
    //     let components =  json.name.split('/');
    //     if(components && components.length > 0)
    //         id = components[components.length - 1];

    //     let fields = json.fields;

    //     let m = new Municipality();
    //     m.id = id;
    //     m.name = this.getValue(fields, 'name', 'stringValue');
    //     m.type = this.getValue(fields, 'type', 'stringValue');
    //     m.district = this.getValue(fields, 'district', 'stringValue');
    //     m.resultsDeclared = this.getValue(fields, 'resultsDeclared', 'booleanValue');
    //     //m.targetResults = this.getValue(fields, 'targetResults', 'stringValue');
    //     m.targetResults = '';
    //     m.wards = this.getArray(fields, 'wards', 'stringValue');

    //     m.wards.sort();

    //     return m;
    // }

    // private static getValue(fields: any, fieldName: string, type: string)
    // {
    //     return fields[fieldName] ? fields[fieldName][type] : null;
    // }

    // private static getArray(fields: any, fieldName: string, arrayItemType: string)
    // {
    //     let retval = [];

    //     if(fields[fieldName] && fields[fieldName]['arrayValue'])
    //     {
    //         retval = fields[fieldName]['arrayValue'].values.map((v) => 
    //         {
    //             return v[arrayItemType];
    //         });
    //     }

    //     return retval;
    // }
}
