import * as React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl";
import { Account } from "../dto/Account";
import { AMMDataService } from "../api/AMMDataService";
import { Municipality } from "../dto/Municipality";
import { DialogSpinner } from "../components/DialogSpinner";
import Utils from "../utils/Utils";

export interface AssignMunicipalityDialogProps 
{
    isOpen: boolean;
    onClose: () => void;
    onAssigned?:() => void;

    account: Account;
}

export interface AssignMunicipalityDialogState
{
    selectedMunicipalityId: string;
    municipalities: Municipality[];
    isLoading: boolean;
}

export class AssignMunicipalitiesDialog extends React.Component<AssignMunicipalityDialogProps, AssignMunicipalityDialogState>
{
    constructor(props: AssignMunicipalityDialogProps)
    {
        super(props);

        this.state = {
            isLoading: false,
            municipalities: [],
            selectedMunicipalityId: ''
        };
    }

    public componentDidMount() 
    {
        if(this.props.isOpen)
        {
            this.refreshMunicipalities();
        }
    }

    public componentWillUnmount() 
    {

    }

    public componentDidUpdate(prevProps: AssignMunicipalityDialogProps, prevState: AssignMunicipalityDialogState)
    {
        if(this.props.isOpen && prevProps.isOpen === false)
        {
            this.setState({ selectedMunicipalityId: '' });
            this.refreshMunicipalities();
        }
    }

    public handleOnAssign()
    {
        if(this.state.selectedMunicipalityId)
        {
            this.setState({ isLoading: true });

            AMMDataService.current().getMunicipality(this.state.selectedMunicipalityId).then((muni) => 
            {
                muni.assigned = muni.assigned || {};
                muni.assigned[this.props.account.uid] = true;

                AMMDataService.current().updateMunicipality(this.state.selectedMunicipalityId, {
                    assigned: muni.assigned
                }).then(() => 
                {
                    this.setState({ isLoading: false });

                    if(this.props.onAssigned)
                        this.props.onAssigned();
                    else
                        this.props.onClose();
                });
            });
        }
    }

    public render() 
    {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.handleOnClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogSpinner showSpinner={this.state.isLoading}>
                    <DialogTitle id="alert-dialog-title">{`Assign municipality to ${this.props.account ? this.props.account.name : ''}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {/* {`Are you sure you want to delete this account? This action cannot be undone.`} */}
                        </DialogContentText>

                        <InputLabel>Select a Municipality</InputLabel>
                        <FormControl margin="normal" fullWidth>
                            <Select
                                fullWidth
                                margin="dense"
                                value={this.state.selectedMunicipalityId}
                                onChange={this.handleSelectChange.bind(this)}>
                                { 
                                    this.state.municipalities.map(m => (<MenuItem key={m.id} value={m.id}>{m.name}</MenuItem>))
                                }
                            </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleOnClose.bind(this)} color="default" autoFocus>Cancel</Button>
                        <Button onClick={this.handleOnAssign.bind(this)} color="primary" variant="contained">Assign</Button>
                    </DialogActions>
                </DialogSpinner>
            </Dialog>
        );
    }

    public handleOnClose()
    {
        if(!this.state.isLoading)
        {
            this.props.onClose();
        }
    }

    public handleSelectChange(e)
    {
        this.setState({ selectedMunicipalityId: e.target.value });
    }

    public refreshMunicipalities()
    {
        this.setState({ isLoading: false });
        AMMDataService.current().getMunicipalities().then((municipalities) => 
        {
            this.setState({ municipalities: municipalities.sort(Utils.sortByProperty('name')) });
        });
    }
}
