import { Component } from "react";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import { AMMDataService } from "../api/AMMDataService";
import { Municipality } from "../dto/Municipality";
import { RouteComponentProps } from "react-router";
import { Candidate } from "../dto/Candidate";
import { DeclareResultsDialog } from "../dialogs/DeclareResultsDialog";
import { BreadcrumbLink } from "../components/BreadcrumbLink";
import { Breadcrumb } from "../components/Breadcrumb";

export interface VotingResultsProps extends RouteComponentProps<any>
{
    
}

export interface VotingResultsState
{
    municipality: Municipality;
    candidates: Candidate[];
    isLoading: boolean;
    headCandidates: Candidate[];
    wards: any;
    saveDialogOpen: boolean;
}

export class VotingResults extends Component<VotingResultsProps, VotingResultsState>
{
    constructor(props: VotingResultsProps) 
    {
        super(props);

        this.state = {
            municipality: null,
            candidates: [],
            headCandidates: [],
            isLoading: false,
            wards: {},
            saveDialogOpen: false
        };
    }

    public componentDidMount() 
    {
        this.refresh();
    }

    public componentWillUnmount() 
    {

    }

    public refresh()
    {
        let muniId = this.props.match.params['municipalityId'];
        if(muniId)
        {
            this.setState({ isLoading: true });

            let muniPromise = AMMDataService.current().getMunicipality(muniId).then((municipality) => 
            {
                this.setState({ municipality: municipality });
            });

            // TODO: get all assigned municipalities
            let candidatePromise = AMMDataService.current().getCandidatesForMunicipality(muniId).then((candidates) => 
            {
                let headCandidates = candidates.filter((c) => 
                { 
                    let pos = c.position.toLowerCase();
                    return pos === 'mayor' || pos === 'reeve' || pos === 'head of council';
                });

                let councilCandidates = candidates.filter((c) => 
                {
                    return headCandidates.indexOf(c) === -1;
                });

                let wards = {};
                councilCandidates.forEach((c) => 
                {
                    let a = wards[c.ward] || [];
                    a.push(c);
                    wards[c.ward] = a;
                });

                this.setState({ 
                    candidates: candidates,
                    headCandidates: headCandidates,
                    wards: wards
                 });
            });

            Promise.all([muniPromise, candidatePromise]).then((results) => 
            {
                this.setState({ isLoading: false });
            });
        }
    }

    public declareResults()
    {
        const promises: Promise<any>[] = [];

        this.setState({ isLoading: true, saveDialogOpen: true });

        this.state.candidates.forEach((c) => 
        {
            promises.push(AMMDataService.current().updateCandidate(c.id, { votes: c.votes || 0, elected: c.elected || 0 }));
        });

        promises.push(AMMDataService.current().updateMunicipality(this.state.municipality.id, { resultsDeclared: true }));

        Promise.all(promises).then((results) => 
        {
            this.setState({ isLoading: false, saveDialogOpen: false });
            this.props.history.push(`/admin/municipalities/${this.props.match.params['municipalityId']}`);
        }).catch((error) => 
        {
            alert(error);
        });
    }

    public render() 
    {
        let id = '';
        let name = '';
        let type = '';
        let district = '';
        let targetResults = '';

        if(this.state.municipality)
        {   
            let muni = this.state.municipality;

            name = muni.name;
            type = muni.type;
            district = muni.district;
            targetResults = muni.targetResults;
        }
        
        return (
            <div className="tab-municipality-details">
                <Breadcrumb>
                    <BreadcrumbLink href="/admin/municipalities">Municipality List</BreadcrumbLink>
                    <span className="divider">/</span>
                    <BreadcrumbLink href={`/admin/municipalities/${this.props.match.params['municipalityId']}`}>{name}</BreadcrumbLink>
                    <span className="divider">/</span>
                    <BreadcrumbLink>Voting Results</BreadcrumbLink>
                </Breadcrumb>
                
                { name ? 
                    <h1>{name}</h1> :
                    <div className="placeholder h1"></div>
                }

                <Paper>
                    <h3>Voting Results</h3>
                    <p>{targetResults}</p>

                    {this.generateHeadCandidateSection(this.state.headCandidates)}

                    {this.generateWardSections(this.state.wards)}

                    <div style={{ marginTop: '40px', textAlign: 'right' }}>
                        <Button onClick={this.onCancel.bind(this)} color="default" style={{ marginRight: '8px'}}>Cancel</Button>
                        <Button 
                            color="primary" 
                            variant="contained" 
                            onClick={() => { this.setState({ saveDialogOpen: true }); }}
                            disabled={this.state.isLoading}>
                            Declare Results
                        </Button>
                    </div>

                </Paper>

                <DeclareResultsDialog
                    isOpen={this.state.saveDialogOpen}
                    onSave={this.declareResults.bind(this)}
                    onClose={this.onClose.bind(this)}
                />

            </div>
        );
    }

    private onCancel()
    {
        this.props.history.push(`/admin/municipalities/${this.state.municipality.id}`);
    }

    private onClose()
    {
        this.setState({ saveDialogOpen: false });
    }

    private generateHeadCandidateSection(candidates: Candidate[])
    {
        candidates.sort(Candidate.sort);

        return (
            <div style={{ marginTop: '40px' }}>
                <h3>Mayor / Reeve / Head of Council</h3>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '220px' }}>Name</TableCell>
                            <TableCell>Total Votes</TableCell>
                            <TableCell>Elected</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { candidates.map((d) => {
                            return (
                                <TableRow key={d.id}>
                                    <TableCell component="th" scope="row">{Candidate.fullName(d)}</TableCell>
                                    <TableCell>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            value={d.votes !== null ? d.votes : ''}
                                            onChange={(e) => { this.updateCandidateVotes(d, e.target.value); this.forceUpdate(); }}
                                            disabled={this.state.isLoading}
                                            margin="none" />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            select
                                            placeholder="Select a position"
                                            disabled={this.state.isLoading}
                                            value={d.elected ? d.elected.toString() : '0'}
                                            onChange={(e) => { d.elected = parseInt(e.target.value); this.forceUpdate(); }}
                                            margin="none">
                                            <MenuItem value="0">Not Elected</MenuItem>
                                            <MenuItem value="1">Elected</MenuItem>
                                            <MenuItem value="2">Acclaimed</MenuItem>
                                        </TextField>
                                    </TableCell>
                                </TableRow>);
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        );
    }

    private updateCandidateVotes(c: Candidate, votes: string)
    {
        let voteInt = Number.parseInt(votes);
        if(Number.isFinite(voteInt))
        {
            c.votes = voteInt;
        }
        else
        {
            c.votes = null;
        }

        return c;
    }

    private generateWardSections(wards: any)
    {
        let retval = [];
        let sortedWardNames = [];

        for(let ward in wards)
        {
            if(wards.hasOwnProperty(ward))
                sortedWardNames.push(ward);
        }

        sortedWardNames.sort();
        sortedWardNames.forEach((name) => 
        {
            let candidates: Candidate[] = wards[name];
            candidates.sort(Candidate.sort);

            retval.push(
                <div key={name} style={{ marginTop: '40px' }}>
                    <h3>Councillor Candidates - {name}</h3>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '220px' }}>Name</TableCell>
                                <TableCell>Total Votes</TableCell>
                                <TableCell>Elected</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { candidates.map((d) => {
                                return (
                                    <TableRow key={d.id}>
                                        <TableCell component="th" scope="row">{Candidate.fullName(d)}</TableCell>
                                        <TableCell>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            value={d.votes != null ? d.votes : ''}
                                            onChange={(e) => { this.updateCandidateVotes(d, e.target.value); this.forceUpdate(); }}
                                            disabled={this.state.isLoading}
                                            margin="none" />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                select
                                                placeholder="Select a position"
                                                disabled={this.state.isLoading}
                                                value={d.elected ? d.elected.toString() : '0'}
                                                onChange={(e) => { d.elected = parseInt(e.target.value); this.forceUpdate(); }}
                                                margin="none">
                                                <MenuItem value="0">Not Elected</MenuItem>
                                                <MenuItem value="1">Elected</MenuItem>
                                                <MenuItem value="2">Acclaimed</MenuItem>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>);
                                })
                            }
                        </TableBody>
                    </Table>
                </div>
            );
        });

        return retval;
    }
}
