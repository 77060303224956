import { Component } from "react";
import i18n from "../utils/i18n";

export interface LogoProps
{
    className?: string;
    variant?: 'default' | 'light';
    showText?: boolean;
}

export interface LogoState
{
}

export class Logo extends Component<LogoProps, LogoState>
{
    private static defaultProps: Partial<LogoProps> = {
        showText: true
    };

    constructor(props: LogoProps)
    {
        super(props);

        this.state = {
        }
    }

    public componentDidMount()
    {

    }

    public render()
    {   
        let additionalClasses = this.props.className || '';

        if(this.props.variant === 'light')
        {
            additionalClasses += ' light';
        }

        return (
            <div className={'component-logo' + (additionalClasses ? ` ${additionalClasses}`: '')}>
                <a href="/" className="logo-image"></a>
                { this.props.showText &&
                    <div className="logo-text">
                        {i18n.t('Title-1')}<br />{i18n.t('Title-2')}
                    </div>
                }
            </div>
        );
    }
}
