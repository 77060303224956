import { Component } from "react";

export interface PanelProps
{
    className?: string;
}

export interface PanelState
{
    entering: boolean;
}

export class Panel extends Component<PanelProps, PanelState>
{
    constructor(props: PanelProps)
    {
        super(props);

        this.state = {
            entering: true
        }
    }

    public componentDidMount()
    {
        
    }

    public render()
    {
        return (
            <div className={'component-panel' + (this.props.className ? ` ${this.props.className}`: '')}>
                {this.props.children}
            </div>
        );
    }
}