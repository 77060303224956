import { Component } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Account } from "../dto/Account";
import { AMMDataService } from "../api/AMMDataService";
import { Municipality } from "../dto/Municipality";
import { AccountEditDialog } from "../dialogs/AccountEditDialog";
import { AssignMunicipalitiesDialog } from "../dialogs/AssignMunicipalityDialog";
import { AccountRemoveMunicipalityDialog } from "../dialogs/AccountRemoveMunicipalityDialog";
import { Breadcrumb } from "../components/Breadcrumb";
import { BreadcrumbLink } from "../components/BreadcrumbLink";
import Utils from "../utils/Utils";

export interface SEODetailsProps extends RouteComponentProps<any>
{
    
}

export interface SEODetailsState
{
    account: Account;
    assignedMunicipalities: Municipality[];
    selectedMunicipality: Municipality;
    editDialogOpen: boolean;
    assignDialogOpen: boolean;
    removeDialogOpen: boolean;
    isLoading: boolean;
}

export class SEODetails extends Component<SEODetailsProps, SEODetailsState>
{
    constructor(props: SEODetailsProps) 
    {
        super(props);

        this.state = {
            account: null,
            assignedMunicipalities: [],
            selectedMunicipality: null,
            editDialogOpen: false,
            assignDialogOpen: false,
            removeDialogOpen: false,
            isLoading: false
        };
    }

    public componentDidMount() 
    {
        this.refresh();
    }

    public componentWillUnmount() 
    {

    }

    public refresh()
    {
        this.setState({ isLoading: true });

        let accountId = this.props.match.params['accountId'];
        if(accountId)
        {
            let seoPromise = AMMDataService.current().getSEOAccount(accountId).then((account) => 
            {
                this.setState({ account: account });
            });

            let muniPromise = AMMDataService.current().getMunicipalitiesForSEO(accountId).then((municipalities) => 
            {
                this.setState({ assignedMunicipalities: municipalities.sort(Utils.sortByProperty('name')) });
            });

            Promise.all([seoPromise, muniPromise]).then((results) => 
            {
                this.setState({ isLoading: false });
            });
        }
    }

    public render() 
    {
        let name = '';
        let email = '';

        if(this.state.account)
        {
            name = this.state.account.name;
            email = this.state.account.email;
        }
        
        return (
            <div className="tab-account-details">
                <Breadcrumb>
                    <BreadcrumbLink href="/admin/accounts">Senior Election Officials List</BreadcrumbLink>
                    <span className="divider">/</span>
                    <BreadcrumbLink>{name}</BreadcrumbLink>
                </Breadcrumb>
                
                {
                    name ? 
                        <h1>{name}</h1> :
                        <div className="placeholder h1"></div>
                }

                <Paper>
                    <h3>Account</h3>
                    <Button 
                        onClick={e => this.setState({ editDialogOpen: true })}
                        disabled={this.state.isLoading}
                        variant="contained" 
                        color="primary">
                        Edit Account
                    </Button>

                    <div className="account-details">
                        <div><label>Email</label><p>{email}</p></div>
                        <div><label>Name</label><p>{name}</p></div>
                        <div><label>Password</label><p>********</p></div>
                    </div>

                </Paper>

                <Paper>
                    <h3>Assigned Municipalities</h3>
                    <Button 
                        onClick={e => this.setState({ assignDialogOpen: true })}
                        disabled={this.state.isLoading}
                        variant="contained"
                        color="primary">
                        Assign Municipality
                    </Button>

                    <Table style={{ marginTop: '10px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Municipality</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>District</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.assignedMunicipalities.map((m) => {
                                    return (
                                        <TableRow key={m.id}>
                                            <TableCell component="th" scope="row">
                                                <Link to={`/admin/municipalities/${m.id}`}>{m.name}</Link>
                                            </TableCell>
                                            <TableCell>{m.type}</TableCell>
                                            <TableCell>{m.district}</TableCell>
                                            <TableCell>
                                                <Button size="small" variant="outlined" onClick={e => this.setState({ removeDialogOpen: true, selectedMunicipality: m })}>Remove</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>

                </Paper>

                <AccountEditDialog
                    account={this.state.account}
                    isOpen={this.state.editDialogOpen}
                    onDeleted={() => { this.setState({ editDialogOpen: false }); this.props.history.push('/admin/accounts'); }}
                    onSaved={() => { this.refresh(); this.setState({ editDialogOpen: false }); }}
                    onClose={() => { this.setState({ editDialogOpen: false }); }} />

                <AssignMunicipalitiesDialog
                    account={this.state.account}
                    isOpen={this.state.assignDialogOpen}
                    onAssigned={() => { this.refresh(); this.setState({ assignDialogOpen: false }); }}
                    onClose={() => { this.setState({ assignDialogOpen: false }); }} />

                <AccountRemoveMunicipalityDialog
                    account={this.state.account}
                    selectedMunicipality={this.state.selectedMunicipality}
                    isOpen={this.state.removeDialogOpen}
                    onRemoved={() => { this.refresh(); this.setState({ removeDialogOpen: false }); }}
                    onClose={() => { this.setState({ removeDialogOpen: false }); }} />

            </div>
        );
    }
}
